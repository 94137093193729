<script>
import SendSalesInfoSheetExample from "@/components/widgets/SendSalesInfoSheetExample.vue";

import {layoutComputed} from "@/state/helpers";

export default {
	components: {
		SendSalesInfoSheetExample,
	},
	data() {
		return {
			settings: {
				minScrollbarLength: 60,
			},
		};
	},
	computed: {
		...layoutComputed,
		layoutType: {
			get() {
				return this.$store ? this.$store.state.layout.layoutType : {} || {};
			},
		},
		userClaims: {
			get() {
				return this.$store ? this.$store.getters["auth/claims"] : {} || {};
			},
		},
	},

	watch: {
		$route: {
			handler: "onRoutechange",
			immediate: true,
			deep: true,
		},
	},
	created() {},
	mounted() {
		this.initActiveMenu();
		if (this.rmenu == "vertical" && this.layoutType == "twocolumn") {
			document.documentElement.setAttribute("data-layout", "vertical");
		}
		document.getElementById("overlay").addEventListener("click", () => {
			document.body.classList.remove("vertical-sidebar-enable");
		});

		window.addEventListener("resize", () => {
			if (this.layoutType == "twocolumn") {
				var windowSize = document.documentElement.clientWidth;
				if (windowSize < 767) {
					document.documentElement.setAttribute("data-layout", "vertical");
					this.rmenu = "vertical";
					localStorage.setItem("rmenu", "vertical");
				} else {
					document.documentElement.setAttribute("data-layout", "vertical");
					this.rmenu = "twocolumn";
					localStorage.setItem("rmenu", "twocolumn");
					setTimeout(() => {
						this.initActiveMenu();
					}, 50);
				}
			}
		});
		if (document.querySelectorAll(".navbar-nav .collapse")) {
			let collapses = document.querySelectorAll(".navbar-nav .collapse");

			collapses.forEach((collapse) => {
				// Hide sibling collapses on `show.bs.collapse`
				collapse.addEventListener("show.bs.collapse", (e) => {
					e.stopPropagation();
					let closestCollapse = collapse.parentElement.closest(".collapse");
					if (closestCollapse) {
						let siblingCollapses = closestCollapse.querySelectorAll(".collapse");
						siblingCollapses.forEach((siblingCollapse) => {
							if (siblingCollapse.classList.contains("show")) {
								siblingCollapse.classList.remove("show");
								siblingCollapse.parentElement.firstChild.setAttribute("aria-expanded", "false");
							}
						});
					} else {
						let getSiblings = (elem) => {
							// Setup siblings array and get the first sibling
							let siblings = [];
							let sibling = elem.parentNode.firstChild;
							// Loop through each sibling and push to the array
							while (sibling) {
								if (sibling.nodeType === 1 && sibling !== elem) {
									siblings.push(sibling);
								}
								sibling = sibling.nextSibling;
							}
							return siblings;
						};
						let siblings = getSiblings(collapse.parentElement);
						siblings.forEach((item) => {
							if (item.childNodes.length > 2) {
								item.firstElementChild.setAttribute("aria-expanded", "false");
								item.firstElementChild.classList.remove("active");
							}
							let ids = item.querySelectorAll("*[id]");
							ids.forEach((item1) => {
								item1.classList.remove("show");
								item1.parentElement.firstChild.setAttribute("aria-expanded", "false");
								item1.parentElement.firstChild.classList.remove("active");
								if (item1.childNodes.length > 2) {
									let val = item1.querySelectorAll("ul li a");

									val.forEach((subitem) => {
										if (subitem.hasAttribute("aria-expanded")) subitem.setAttribute("aria-expanded", "false");
									});
								}
							});
						});
					}
				});

				// Hide nested collapses on `hide.bs.collapse`
				collapse.addEventListener("hide.bs.collapse", (e) => {
					e.stopPropagation();
					let childCollapses = collapse.querySelectorAll(".collapse");
					childCollapses.forEach((childCollapse) => {
						let childCollapseInstance = childCollapse;
						childCollapseInstance.classList.remove("show");
						childCollapseInstance.parentElement.firstChild.setAttribute("aria-expanded", "false");
					});
				});
			});
		}
	},

	methods: {
		onRoutechange(ele) {
			this.initActiveMenu(ele.path);
			if (document.getElementsByClassName("mm-active").length > 0) {
				const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
				if (currentPosition > 500) if (this.$refs.isSimplebar) this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
			}
		},

		initActiveMenu() {
			const pathName = window.location.pathname;
			const ul = document.getElementById("navbar-nav");
			if (ul) {
				//console.log(ul);
				const items = Array.from(ul.querySelectorAll("a.nav-link"));
				//console.log(items);
				let activeItems = items.filter((x) => x.classList.contains("active"));
				this.removeActivation(activeItems);
				let matchingMenuItem = items.find((x) => {
					return x.getAttribute("href") === pathName;
				});
				if (matchingMenuItem) {
					this.activateParentDropdown(matchingMenuItem);
				} else {
					var id = pathName.replace("/", "");
					if (id) document.body.classList.add("twocolumn-panel");
					this.activateIconSidebarActive(pathName);
				}
			}
		},

		updateMenu(e, event) {
			document.body.classList.remove("twocolumn-panel");
			const ul = document.getElementById("navbar-nav");
			if (ul) {
				const items = Array.from(ul.querySelectorAll(".show"));
				items.forEach((item) => {
					item.classList.remove("show");
				});
			}
			const icons = document.getElementById("two-column-menu");
			if (icons) {
				const activeIcons = Array.from(icons.querySelectorAll(".nav-icon.active"));
				activeIcons.forEach((item) => {
					item.classList.remove("active");
				});
			}
			document.getElementById(e).classList.add("show");
			event.target.classList.add("active");
			this.activateIconSidebarActive("#" + e);
		},

		removeActivation(items) {
			items.forEach((item) => {
				if (item.classList.contains("menu-link")) {
					if (!item.classList.contains("active")) {
						item.setAttribute("aria-expanded", false);
					}
					item.nextElementSibling.classList.remove("show");
				}
				if (item.classList.contains("nav-link")) {
					if (item.nextElementSibling) {
						item.nextElementSibling.classList.remove("show");
					}
					item.setAttribute("aria-expanded", false);
				}
				item.classList.remove("active");
			});
		},

		activateParentDropdown(item) {
			// navbar-nav menu add active
			item.classList.add("active");
			let parentCollapseDiv = item.closest(".collapse.menu-dropdown");
			if (parentCollapseDiv) {
				// to set aria expand true remaining
				parentCollapseDiv.classList.add("show");
				parentCollapseDiv.parentElement.children[0].classList.add("active");
				parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
				if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
					if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown").previousElementSibling) {
						if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown").previousElementSibling.parentElement.closest(".collapse.menu-dropdown")) {
							const grandparent = parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown").previousElementSibling.parentElement.closest(".collapse.menu-dropdown");
							this.activateIconSidebarActive("#" + grandparent.getAttribute("id"));
							grandparent.classList.add("show");
						}
					}
					this.activateIconSidebarActive("#" + parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown").getAttribute("id"));

					parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
					if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling) parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
					return false;
				}
				this.activateIconSidebarActive("#" + parentCollapseDiv.getAttribute("id"));
				return false;
			}
			return false;
		},

		activateIconSidebarActive(id) {
			var menu = document.querySelector("#two-column-menu .simplebar-content-wrapper a[href='" + id + "'].nav-icon");
			if (menu !== null) {
				menu.classList.add("active");
			}
		},
	},
};
</script>

<template>
	<BContainer fluid>
		<div id="two-column-menu"></div>

		<template v-if="layoutType === 'vertical' || layoutType === 'semibox'">
			<ul id="navbar-nav" class="navbar-nav h-100">
				<li class="menu-title">
					<span data-key="t-menu">Menu</span>
				</li>

				<li v-if="userClaims?.p_viewDeals" class="nav-item">
					<router-link to="/apps/deals" class="nav-link menu-link" data-key="t-deals">
						<i class="bx bx-briefcase"></i>
						<span data-key="t-base-ui">Deals</span>
					</router-link>
					<div id="createUi" class="menu-dropdown mega-dropdown-menu">
						<ul v-if="userClaims?.p_createDeals" class="nav nav-sm flex-column">
							<li class="nav-item">
								<router-link to="/apps/deals/create" class="nav-link" data-key="t-deals-create">
									<span data-key="t-base-ui">Create</span>
								</router-link>
							</li>
						</ul>
					</div>
				</li>

				<SendSalesInfoSheetExample v-if="userClaims?.p_sendSalesInfoSheetExample" />
				<li class="menu-title">
					<i class="mdi mdi-settings-helper"></i>
					<span data-key="t-components">Dashboards</span>
				</li>
				<li class="nav-item">
					<router-link to="/apps/event-types/sales" class="nav-link menu-link" data-key="t-bookingLinks">
						<i class="mdi mdi-calendar-account"></i>
						<span data-key="t-base-ui">Sales Booking Links</span>
					</router-link>
				</li>
				<li v-if="userClaims?.p_viewTrainingGrantEmails" class="nav-item">
					<router-link to="/dashboard/training-emails" class="nav-link menu-link" data-key="t-training-emails">
						<i class="pi pi-envelope"></i>
						<span data-key="t-base-ui">Training Grant Emails</span>
					</router-link>
				</li>
				<li v-if="userClaims?.p_viewVoicemails" class="nav-item">
					<router-link to="/dashboard/voicemails" class="nav-link menu-link" data-key="t-voicemails">
						<i class="mdi mdi-voicemail"></i>
						<span data-key="t-base-ui">Voicemails</span>
					</router-link>
				</li>
				<li class="menu-title">
					<i class="mdi mdi-settings-helper"></i>
					<span data-key="t-components">Reports</span>
				</li>
				<li v-if="userClaims?.p_viewMeetingRecordings" class="nav-item">
					<router-link to="/dashboard/sales-stats" class="nav-link menu-link" data-key="t-sales-stats">
						<i class="pi pi-chart-bar"></i>
						<span data-key="t-base-ui">Sales Stats</span>
					</router-link>
				</li>
				<li v-if="userClaims?.roles?.admin" class="nav-item">
					<router-link to="/dashboard/virtual-assistants" class="nav-link menu-link" data-key="t-feature-usage">
						<i class="pi pi-calendar"></i>
						<span data-key="t-base-ui">Virtual Assistants</span>
					</router-link>
				</li>
				<li v-if="userClaims?.p_viewMeetingRecordings" class="nav-item">
					<router-link to="/dashboard/meetings" class="nav-link menu-link" data-key="t-meeting-recordings">
						<i class="mdi mdi-play-outline"></i>
						<span data-key="t-base-ui">Meeting Recordings</span>
					</router-link>
				</li>
				<li v-if="userClaims?.roles?.admin" class="nav-item">
					<router-link to="/dashboard/feature-usage" class="nav-link menu-link" data-key="t-feature-usage">
						<i class="pi pi-th-large"></i>
						<span data-key="t-base-ui">App Feature Usage</span>
					</router-link>
				</li>

				<div v-if="userClaims?.p_settingsAccess">
					<li class="menu-title">
						<i class="mdi mdi-settings-helper"></i>
						<span data-key="t-components">Settings</span>
					</li>
					<li class="nav-item">
						<a class="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarUI">
							<i class="bx bxs-school"></i>
							<span data-key="t-base-ui">Training</span>
						</a>
						<div id="sidebarUI" class="collapse menu-dropdown mega-dropdown-menu">
							<BRow>
								<BCol col lg="4">
									<ul v-if="userClaims?.p_viewTrainingCompanies" class="nav nav-sm flex-column">
										<li class="nav-item">
											<router-link to="/settings/training/companies" class="nav-link" data-key="t-trainingCompanies">Training Companies</router-link>
										</li>
										<li class="nav-item">
											<router-link to="/settings/training/courses" class="nav-link" data-key="t-trainingCourses">Training Courses</router-link>
										</li>
									</ul>
									<ul class="nav nav-sm flex-column">
										<li class="nav-item">
											<router-link to="/settings/meetings/event-types" class="nav-link" data-key="t-eventTypes">Event Types</router-link>
										</li>
									</ul>
								</BCol>
							</BRow>
						</div>
					</li>

					<li v-if="userClaims?.p_manageUsers" class="nav-item">
						<router-link to="/settings/users" class="nav-link menu-link" data-key="t-users">
							<i class="bx bx-group"></i>
							<span data-key="t-base-ui">Edit Users</span>
						</router-link>
					</li>
					<li v-if="userClaims?.p_manageUsers" class="nav-item">
						<router-link to="/settings/deal-statuses" class="nav-link menu-link" data-key="t-statuses">
							<i class="pi pi-tags"></i>
							<span data-key="t-base-ui">Edit Deal Statuses</span>
						</router-link>
					</li>

					<li v-if="userClaims?.p_viewReferralSources" class="nav-item">
						<router-link to="/settings/referral-sources" class="nav-link menu-link" data-key="t-referral-sources">
							<i class="mdi mdi-folder-account"></i>
							<span data-key="t-base-ui">Referral Sources</span>
						</router-link>
					</li>
				</div>

				<!-- end Dashboard Menu -->
			</ul>
		</template>
	</BContainer>
</template>
