import { createRouter, createWebHistory } from "vue-router";
//import store from "@/state/store";
import { getCurrentUser } from "vuefire";

import { analytics, logEvent } from "@/common/data/firestore/firebase";

import appConfig from "../../app.config";

//import axios from 'axios';
import routes from "./routes";

const router = createRouter({
	history: createWebHistory(),
	routes,
	// Use the HTML5 history API (i.e. normal-looking routes)
	// instead of routes with hashes (e.g. example.com/#/about).
	// This may require some server configuration in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	mode: "history",
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve,) => {
			if (savedPosition) {
				// create an Observer instance
				const resizeObserver = new ResizeObserver(entries => {

					if (entries[0].target.clientHeight <= savedPosition.top + screen.height) {
						resolve(savedPosition);
						resizeObserver.disconnect();
					}
				});

				// start observing a DOM node
				resizeObserver.observe(document.body);
			} else {
				resolve({ top: 0 });
			}
		});
	}
});
/*
// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  
  if (!authRequired) return next();
  
  axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt'); // for all requests
  await axios.get('https://api-node.themesbrand.website/profile').then((data) => {
	localStorage.setItem('userdata', JSON.stringify(data.data.user));
	localStorage.setItem('userid', data.data.user._id);
	localStorage.setItem('user', JSON.stringify(data.data.user));
	next();
  }).catch(() => {
	next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
  });
});
*/
/*
router.beforeEach(async (to) => {
	// routes with `meta: { requiresAuth: true }` will check for the users, others won't
	if (to.meta.requiresAuth) {
		const currentUser = await getCurrentUser();
		// if the user is not logged in, redirect to the login page
		if (!currentUser) {
			return {
				path: "/login",
				query: {
					// we keep the current path in the query so we can redirect to it after login
					// with `router.push(route.query.redirect || '/')`
					redirect: to.fullPath,
				},
			};
		}
	} else if (to.name === "login") {
		const currentUser = await getCurrentUser();
		if (currentUser) {
			return {
				path: "/",
			};
		}
	}
});*/

router.beforeEach(async (routeTo) => {
	// routes with `meta: { requiresAuth: true }` will check for the users, others won't
	const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

	if (authRequired) {
		const currentUser = await getCurrentUser();
		// if the user is not logged in, redirect to the login page
		if (!currentUser) {
			return {
				path: "/login",
				query: {
					// we keep the current path in the query so we can redirect to it after login
					// with `router.push(route.query.redirect || '/')`
					redirectFrom: routeTo.fullPath,
				},
			};
		}
	} else if (routeTo.name === "login" || routeTo.name === "register" || routeTo.name === "forgot-password") {
		const currentUser = await getCurrentUser();
		if (currentUser) {
			return {
				path: routeTo.query.redirectFrom || "/",
			};
		}
	}
});

/*
router.beforeEach(async (routeTo, routeFrom, next) => {
	if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
		console.log("firebase");
		// Check if auth is required on this route
		// (including nested routes).
		const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

		// If auth isn't required for the route, just continue.
		if (!authRequired) return next();
		// Using async/await to check loggedIn status
		const loggedIn = store.getters["auth/loggedIn"];
		console.log("loggedIn:", loggedIn);

		if (loggedIn) {
			try {
				const validUser = await store.dispatch("auth/validate");
				console.log("Valid user:", validUser);
				validUser ? next() : redirectToLogin();
			} catch (error) {
				console.error(error);
				redirectToLogin();
			}
		} else {
			redirectToLogin();
		}

		console.log("router/index.js store.getters auth/loggedIn: ", store.getters["auth/loggedIn"]);
		// If auth is required and the user is NOT currently logged in,
		// redirect to login.
		redirectToLogin();

		// eslint-disable-next-line no-unused-vars
		// eslint-disable-next-line no-inner-declarations
		function redirectToLogin() {
			// Pass the original route to the login component
			next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
		}
	} else {
		const publicPages = ["/login", "/register", "/forgot-password"];
		const authpage = !publicPages.includes(routeTo.path);
		const loggeduser = localStorage.getItem("user");

		console.log("router/index.js publicPages: ", publicPages);
		console.log("router/index.js loggedUser: ", loggeduser);
		console.log("router/index.js authpage: ", authpage);
		if (authpage && !loggeduser) {
			return next("/login");
		}

		next();
	}
});
*/
router.beforeEach(async (to, from, next) => {
	const authRequired = await to.matched.some((route) => route.meta.authRequired);
	//const loggedIn = store.getters["auth/loggedIn"]; // Assumes you have a Vuex getter to check if user is logged in
	const currentUser = await getCurrentUser();
	if (authRequired && !currentUser) {
		next({ name: "login", query: { redirectFrom: to.fullPath } });
		return;
	}

	if (currentUser) {
		// If the user is logged in, fetch their claims

		//const userClaims = store.getters["auth/claims"]; // Adjust based on your store

		const userClaimsResponse = await currentUser.getIdTokenResult();
		const userClaims = userClaimsResponse.claims;
		//console.log("router/index.js userClaims: ", userClaims);
		// Check if the route requires any specific claims
		const requiredClaims = to.matched.some((route) => {
			if (route.meta.requiredClaims) {
				// Check each required claim
				return Object.entries(route.meta.requiredClaims).every(([key, value]) => {
					// Adjust the condition based on how your claims are structured
					if (!userClaims[key]) {
						return false;
					}
					return userClaims[key] === value;
				});
			}
			return true; // If no requiredClaims specified, always return true
		});

		if (!requiredClaims) {
			// If user doesn't have the required claims, redirect or show an error
			// For example, redirect to a "not authorized" page or the dashboard
			next({ name: "403" }); // Adjust as necessary
			return;
		}
	}

	next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
	// Create a `beforeResolve` hook, which fires whenever
	// `beforeRouteEnter` and `beforeRouteUpdate` would. This
	// allows us to ensure data is fetched even when params change,
	// but the resolved route does not. We put it in `meta` to
	// indicate that it's a hook we created, rather than part of
	// Vue Router (yet?).
	try {
		// For each matched route...
		for (const route of routeTo.matched) {
			await new Promise((resolve, reject) => {
				// If a `beforeResolve` hook is defined, call it with
				// the same arguments as the `beforeEnter` hook.
				if (route.meta && route.meta.beforeResolve) {
					route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
						// If the user chose to redirect...
						if (args.length) {
							// If redirecting to the same route we're coming from...
							// Complete the redirect.
							next(...args);
							reject(new Error("Redirected"));
						} else {
							resolve();
						}
					});
				} else {
					// Otherwise, continue resolving the route.
					resolve();
				}
			});
		}
		// If a `beforeResolve` hook chose to redirect, just return.
	} catch (error) {
		return;
	}
	document.title = routeTo.meta.title + " | " + appConfig.title;
	// If we reach this point, continue resolving the route.
	next();
});
router.beforeEach(async () => {
	logEvent(analytics, "page_view", { type: "internal" });
});


router.onError((error) => {
	console.error(error);
	//Vue.prototype.$log.error("Failure Reason: ", error.message, error);
	if (/ChunkLoadError:.*failed./i.test(error.message)) {
		//Vue.prototype.$log.error("Reloading Window 1");
		window.location.reload();
	} else if (/Loading.*chunk.*failed./i.test(error.message)) {
		//Vue.prototype.$log.error("Reloading Window 2");
		window.location.reload();
	}
});
export default router;
