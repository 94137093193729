<script>
import simplebar from "simplebar-vue";

import Footer from "@/components/footer";
import Menu from "@/components/menu.vue";
import NavBar from "@/components/nav-bar";
import RightBar from "@/components/right-bar";

import {layoutComputed} from "@/state/helpers";

import router from "@/router";

localStorage.setItem("hoverd", false);

/**
 * Vertical layout
 */
export default {
	components: {NavBar, RightBar, Footer, Menu, simplebar},
	data() {
		return {
			isMenuCondensed: false,
		};
	},
	computed: {
		...layoutComputed,
	},
	created: () => {
		document.body.removeAttribute("data-layout", "horizontal");
		document.body.removeAttribute("data-topbar", "dark");
		document.body.removeAttribute("data-layout-size", "boxed");
		// this.updateSidebarSize();
	},
	mounted() {
		if (localStorage.getItem("hoverd") == "true") {
			document.documentElement.setAttribute("data-sidebar-size", "sm-hover-active");
		}

		document.getElementById("overlay").addEventListener("click", () => {
			document.body.classList.remove("vertical-sidebar-enable");
		});
		if (window.screen.width < 1025) {
			document.documentElement.setAttribute("data-sidebar-size", "sm");
		}

		window.addEventListener("resize", () => {
			document.body.classList.remove("vertical-sidebar-enable");
			document.querySelector(".hamburger-icon").classList.add("open");
			this.updateSidebarSize();
		});
	},
	unmounted() {
		window.removeEventListener("resize", this.updateSidebarSize);
	},
	methods: {
		updateSidebarSize() {
			let sidebarSize = "";
			// Check window.screen.width and update the data-sidebar-size attribute
			if (window.innerWidth < 1025) {
				//this.sidebarSize = "sm";
				sidebarSize = "sm";
			} else {
				//this.sidebarSize = "lg"; // Reset sidebarSize if screen width is >= 1025
				sidebarSize = "lg";
			}
			// Update the data-sidebar-size attribute of document.documentElement
			document.documentElement.setAttribute("data-sidebar-size", sidebarSize);
		},

		initActiveMenu() {
			if (document.documentElement.getAttribute("data-sidebar-size") === "sm-hover") {
				localStorage.setItem("hoverd", true);
				document.documentElement.setAttribute("data-sidebar-size", "sm-hover-active");
			} else if (document.documentElement.getAttribute("data-sidebar-size") === "sm-hover-active") {
				localStorage.setItem("hoverd", false);
				document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
			} else {
				document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
			}
		},
		toggleMenu() {
			document.body.classList.toggle("sidebar-enable");
			if (window.screen.width >= 992) {
				// eslint-disable-next-line no-unused-vars
				router.afterEach((routeTo, routeFrom) => {
					document.body.classList.remove("sidebar-enable");
					document.body.classList.remove("vertical-collpsed");
				});
				document.body.classList.toggle("vertical-collpsed");
			} else {
				// eslint-disable-next-line no-unused-vars
				router.afterEach((routeTo, routeFrom) => {
					document.body.classList.remove("sidebar-enable");
				});
				document.body.classList.remove("vertical-collpsed");
			}
			this.isMenuCondensed = !this.isMenuCondensed;
		},
		toggleRightSidebar() {
			document.body.classList.toggle("right-bar-enabled");
		},
		hideRightSidebar() {
			document.body.classList.remove("right-bar-enabled");
		},
	},
};
</script>

<template>
	<div id="layout-wrapper">
		<NavBar />
		<div>
			<!-- ========== Left Sidebar Start ========== -->
			<!-- ========== App Menu ========== -->
			<div class="app-menu navbar-menu">
				<!-- LOGO -->
				<div class="navbar-brand-box">
					<!-- Dark Logo-->
					<router-link to="/" class="logo logo-dark">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt="" height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-dark.png" alt="" height="17" />
						</span>
					</router-link>
					<!-- Light Logo-->
					<router-link to="/" class="logo logo-light">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt="" height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-light.png" alt="" height="17" />
						</span>
					</router-link>
					<button id="vertical-hover" type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" @click="initActiveMenu">
						<i class="ri-record-circle-line"></i>
					</button>
				</div>

				<simplebar id="scrollbar" ref="scrollbar" class="h-100">
					<Menu></Menu>
				</simplebar>
				<div class="sidebar-background"></div>
			</div>
			<!-- Left Sidebar End -->
			<!-- Vertical Overlay-->
			<div id="overlay" class="vertical-overlay"></div>
		</div>
		<!-- ============================================================== -->
		<!-- Start Page Content here -->
		<!-- ============================================================== -->

		<div class="main-content">
			<div class="page-content">
				<!-- Start Content-->
				<BContainer fluid>
					<slot />
				</BContainer>
			</div>
			<Footer />
		</div>
		<RightBar />
	</div>
</template>
