<template>
	<BDropdown id="page-header-notifications-dropdown" variant="ghost-dark" dropstart class="ms-1 dropdown" :offset="{alignmentAxis: 57, crossAxis: 0, mainAxis: -42}" toggle-class="btn-icon btn-topbar rounded-circle arrow-none" menu-class="dropdown-menu-lg dropdown-menu-end p-0" auto-close="outside">
		<template #button-content>
			<i class="bx bx-bell fs-22" :class="{shake: animateBell}"></i>
			<Transition name="slide-fade">
				<span v-show="unreadNotificationsCount > 0" class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
					<span class="notification-badge">{{ unreadNotificationsCount }}</span>
					<span class="visually-hidden">unread messages</span>
				</span>
			</Transition>
		</template>
		<div class="dropdown-head bg-primary bg-pattern rounded-top">
			<div class="p-3">
				<div class="d-flex justify-content-between">
					<h6 class="m-0 fs-16 fw-semibold text-white">Notifications</h6>
					<span class="badge bg-light text-dark">{{ unreadNotificationsCount }} New</span>
				</div>
			</div>
		</div>
		<div class="dropdown-menu-lg">
			<simplebar data-simplebar style="max-height: 300px" class="pe-2">
				<TransitionGroup name="list" tag="div">
					<div v-for="notification in notifications" :key="notification.id" class="p-2">
						<div class="text-reset notification-item d-block">
							<div class="d-flex">
								<div v-if="!notification.user" class="avatar-xs me-3 flex-shrink-0">
									<span class="avatar-title bg-danger-subtle text-danger rounded-circle fs-16">
										<i class="bx bx-message-square-dots"></i>
									</span>
								</div>
								<img v-else :src="findUserInfo(notification.user)?.photoURL" class="me-3 rounded-circle avatar-xs flex-shrink-0" alt="user-pic" />
								<div class="flex-grow-1">
									<h6 v-if="notification.user" class="mt-0 mb-2">
										{{ findUserInfo(notification.user)?.displayName }}
									</h6>
									<router-link v-if="notification.linkToPath" :key="`${notification.linkToPath}-${notification.id}`" :to="notification.linkToPath">
										<p v-html="notification.details"></p>
									</router-link>
									<p v-else v-html="notification.details"></p>
									<small v-if="notification.timestamp" class="text-muted">
										{{ formatDate(convertDateFromFirebase(notification.timestamp)) }}
									</small>
								</div>
								<Button v-if="!notification.read" v-tooltip="'Mark as read'" icon="pi pi-bell" severity="warning" rounded aria-label="Notification" @click="markAsRead(notification)" />
								<Button v-else v-tooltip="'Mark as unread'" icon="pi pi-check" rounded outlined aria-label="Filter" @click="markAsRead(notification)" />
							</div>
						</div>
					</div>
				</TransitionGroup>
				<template v-if="notifications.length == 0">
					<div class="w-25 w-sm-50 pt-3 mx-auto">
						<img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
					</div>
					<div class="text-center pb-5 mt-2">
						<h6 class="fs-18 fw-semibold lh-base">Hey! You have no notifications</h6>
					</div>
				</template>
				<div v-if="1 == 2" class="text-center p-2">
					<BButton variant="soft-success">View All Notifications</BButton>
				</div>
			</simplebar>
		</div>
	</BDropdown>

	<Dialog v-model:visible="dialogAlertVisible" modal header="Unread Notifications" :style="{width: '35rem'}">
		<span class="font-weight-bold">
			<strong>Did you know you have {{ unreadNotificationsCount }} unread notifications?</strong>
		</span>
		<span class="p-text-secondary block mb-5">
			<br />
			Click the
			<i class="bx bx-bell"></i>
			at the top right to read them.
			<br />
			When you have read the notification click the
			<i class="bx bx-bell"></i>
			beside the individual notifications to mark it as read to prevent this notification from popping up again.
		</span>
		<template #footer>
			<Button type="button" label="Dismiss" @click="dismissNotification"></Button>
		</template>
	</Dialog>
</template>

<script>
import {mapState} from "vuex";

import dayjs from "dayjs";
import simplebar from "simplebar-vue";

import Button from "primevue/button";
import Dialog from "primevue/dialog";

import UsersNotificationsDataService from "@/common/data/firestore/usersNotifications";
import {findUserInfo} from "@/common/utils/findUserInfo";

var relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);
export default {
	components: {simplebar, Button, Dialog},
	data() {
		return {
			notifications: [],
			animateBell: false,
			dialogAlertVisible: false,
		};
	},
	computed: {
		...mapState({
			currentUser: (state) => state.auth.currentUser,
		}),
		unreadNotificationsCount() {
			return this.notifications.filter((n) => !n.read).length;
		},
		showNotificationAlert() {
			return this.notifications.some((n) => !n.read && dayjs().diff(dayjs(n.timestamp.toDate()), "days") > 3);
		},
	},
	watch: {
		currentUser(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.loadNotifications();
			}
		},
		unreadNotificationsCount(newCount, oldCount) {
			if (newCount === 1 && oldCount === 0) {
				this.showBadgeAnimation = true;
				setTimeout(() => (this.showBadgeAnimation = false), 300);
			}
			if (newCount !== oldCount) {
				this.animateBell = true;
				setTimeout(() => (this.animateBell = false), 1000);
			}
		},
		showNotificationAlert(newVal) {
			if (newVal) {
				this.checkIfShowAlert();
			}
		},
	},
	created() {
		this.loadNotifications();
	},
	mounted() {
		this.checkIfShowAlert();
	},
	methods: {
		dismissNotification() {
			this.dialogAlertVisible = false;
			//save in localstorage that the user has seen the notification
			localStorage.setItem("notificationAlert", new Date().toISOString());
		},
		convertDateFromFirebase(date) {
			try {
				return date.toDate();
			} catch (e) {
				return date;
			}
		},
		formatDate(date) {
			if (!date) return "";
			return dayjs(new Date(date)).fromNow();
		},
		async markAsRead(notification) {
			notification.read = !notification.read;
			console.log(notification.id, notification.read);
			try {
				this.$store.dispatch("auth/trackInteraction", {
					userId: this.$store.state.auth.currentUser.uid,
					featureId: "marked-notification-as-read",
				});
			} catch (e) {
				console.error(e);
			}
			await UsersNotificationsDataService.updateReadStatus(this.currentUser.uid, notification.id, notification.read);
		},
		async loadNotifications() {
			if (this.currentUser && this.currentUser.uid) {
				this.notifications = await UsersNotificationsDataService.getAll(this.currentUser.uid);
			}
		},
		findUserInfo,
		checkIfShowAlert() {
			if (this.showNotificationAlert) {
				if (localStorage.getItem("notificationAlert") == null) {
					this.dialogAlertVisible = true;
				} else {
					var date = new Date(localStorage.getItem("notificationAlert"));
					var now = new Date();
					var diff = now - date;
					if (diff > 1000 * 60 * 60 * 24 * 3) {
						this.dialogAlertVisible = true;
					} else {
						this.dialogAlertVisible = false;
					}
				}
			}
		},
	},
};
</script>

<style scoped>
.dropdown-menu-lg {
	max-height: 300px;
	overflow-y: auto;
}
@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

.shake {
	animation: shake 0.5s;
	animation-iteration-count: infinite;
}
.slide-fade-enter-active {
	transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
	transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	opacity: 0;
	transform: translateX(50px);
}
.list-move {
	transition: transform 0.5s ease;
}

@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.notification-slide-in {
	animation: slideInFromTop 1s ease-out forwards;
}
</style>
