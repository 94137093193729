import {useCollection} from "vuefire";
import {collection, doc, orderBy,query, updateDoc, where} from "firebase/firestore";

import {usersCollection} from "./firebase";

// notifications.js

const path = "notifications";

class UsersNotificationsDataService {
	// Retrieve all notifications for a specific user
	// Retrieve all notifications for a specific user
	async getAll(userId) {
		const collectionRef = collection(usersCollection, `${userId}/${path}`);
		const q = query(collectionRef, orderBy("read", "asc"), orderBy("timestamp", "desc"));
		return useCollection(q);
	}

	// Retrieve all unread notifications for a specific user
	async getAllUnread(userId) {
		const collectionRef = collection(usersCollection, `${userId}/${path}`);
		const q = query(collectionRef, where("read", "==", false), orderBy("timestamp", "desc"));
		return useCollection(q);
	}

	// Update a notification's read status
	async updateReadStatus(userId, notificationId, readStatus) {
		const notificationRef = doc(usersCollection, `${userId}/${path}`, notificationId);
		return await updateDoc(notificationRef, {read: readStatus});
	}
}

export default new UsersNotificationsDataService();
