<template>
	<Layout>
		<PageHeader :title="docData?.title || 'Docs'" page-title="Docs" />
		<BCard no-body>
			<BCardBody>
				<div>
					<div v-if="canEdit && editMode && docData?.title !== undefined">
						<FloatLabel>
							<InputText id="title" v-model="docData.title" placeholder="Enter title" />
							<label for="title">Title</label>
						</FloatLabel>
					</div>

					<MyEditor v-if="canEdit && editMode" v-model="docData.body" />

					<div v-if="!editMode && docData?.body">
						<div v-html="docData?.body"></div>
					</div>

					<div v-if="canEdit">
						<Button v-if="!editMode" @click="editMode = true">Edit</Button>
						<Button v-else @click="saveDoc">Save</Button>
						<Button v-if="editMode" severity="secondary" @click="editMode = false">Cancel</Button>
					</div>
				</div>
			</BCardBody>
		</BCard>
	</Layout>
</template>

<script>
import {computed, onMounted, ref, toRefs} from "vue";
import {doc, getDoc, setDoc} from "firebase/firestore"; // Assuming you're using Firestore

import Button from "primevue/button";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";

import {firebaseDb} from "@/common/data/firestore/firebase"; // Adjust the import according to your project setup

import MyEditor from "@/components/MyEditor"; // Adjust the import according to your project setup
import PageHeader from "@/components/page-header";

import store from "@/state/store"; // Import your store

import Layout from "@/layouts/main.vue";

import "quill/dist/quill.snow.css"; // Add this line to your main.js or App.vue

export default {
	components: {
		MyEditor,
		Layout,
		PageHeader,
		Button,
		InputText,
		FloatLabel,
	},
	props: {
		docId: {
			type: [String, Array],
			default: "",
		},
	},
	setup(props) {
		const {docId} = toRefs(props);
		const docData = ref({title: "", body: "<p>Test</p>"});
		const canEdit = computed(() => store.getters["auth/claims"]?.p_docEditor);
		const editMode = ref(false);

		onMounted(async () => {
			const docRef = doc(firebaseDb, "docs", docId.value);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				docData.value = docSnap.data();
			} else {
				docData.value = {title: "", body: ""};
			}
		});
		const saveDoc = async () => {
			const docRef = doc(firebaseDb, "docs", docId.value);
			await setDoc(docRef, docData.value);
			editMode.value = false;
			//	this.$router.push(`/doc/${docId.value}`);
		};

		return {docData, canEdit, editMode, saveDoc};
	},
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
