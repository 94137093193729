import { setUserId } from "firebase/analytics";

import * as Sentry from "@sentry/vue";

import { analytics, logEvent } from "@/common/data/firestore/firebase";
import UsersDataService from "@/common/data/firestore/users.js";

import { getFirebaseBackend } from "../../authUtils.js";

export const state = {
	currentUser: JSON.parse(sessionStorage.getItem("authUser") || null),
	currentClaims: JSON.parse(sessionStorage.getItem("authClaims") || null),
};

export const mutations = {
	SET_CURRENT_USER(state, newValue) {
		state.currentUser = newValue;
		saveState("auth.currentUser", newValue);
	},
	SET_CLAIMS(state, newValue) {
		state.currentClaims = newValue;
		//	sessionStorage.setItem("authClaims", newValue);
		saveState("auth.currentClaims", newValue); // Correctly store claims in session storage
	},
};

export const getters = {
	// Whether the user is currently logged in.
	loggedIn(state) {
		return !!state.currentUser;
	},
	// Returns the current user.

	// Returns the current user's claims.
	claims(state) {
		// Parse the claims from session storage if state.claims is not yet populated
		return state.currentClaims;
	},
};

export const actions = {
	// This is automatically run in `src/state/store.js` when the app
	// starts, along with any other actions named `init` in other modules.
	// eslint-disable-next-line no-unused-vars
	init({ state, dispatch }) {
		dispatch("validate");
	},

	async logInGoogle({ commit, dispatch, getters }, { email } = {}) {
		if (getters.loggedIn) return dispatch("validate");

		try {
			const { user, token } = await getFirebaseBackend().loginUserGoogle(email);
			commit("SET_CURRENT_USER", user);
			localStorage.setItem("user", JSON.stringify(user));

			// Fetch and store user claims
			const claims = await getFirebaseBackend().getCurrentUserClaims();
			commit("SET_CLAIMS", claims);
			console.log("User Logged In: ", user, "Token: ", token, "Claims: ", claims);

			// Track interaction for logging in (async)
			dispatch("trackInteraction", { userId: user.uid, featureId: "login-google" });
			setUserId(analytics, user.uid);
			logEvent(analytics, "login", { method: "Google" });
			try {
				if (user.email) {
					var userObj = {
						email: user.email,
						id: user.uid,
						username: user.email,
					};
					Sentry.setUser(userObj);
				}
			} catch (e) {
				console.error(e);
			}

			return { user, token, claims };
		} catch (error) {
			console.log("Error: ", error);
			return Promise.reject(error);
		}
	},

	async logIn({ commit, dispatch, getters }, { email, password } = {}) {
		if (getters.loggedIn) return dispatch("validate");

		try {
			const user = await getFirebaseBackend().loginUser(email, password);
			commit("SET_CURRENT_USER", user);
			localStorage.setItem("user", JSON.stringify(user));

			// Track interaction for logging in (async)
			dispatch("trackInteraction", { userId: user.uid, featureId: "login" });
			setUserId(analytics, user.uid);

			logEvent(analytics, "login", { method: "Email" });


			try {
				if (user.email) {
					var userObj = {
						email: user.email,
						id: user.uid,
						username: user.email,
					};
					Sentry.setUser(userObj);
				}
			} catch (e) {
				console.error(e);
			}

			return user;
		} catch (error) {
			console.log("Error: ", error);
			return Promise.reject(error);
		}
	},

	async trackInteraction(_, { userId, featureId }) {
		logEvent(analytics, featureId, { userId });

		try {
			await UsersDataService.trackInteraction(userId, featureId);
		} catch (error) {
			console.error("Failed to track interaction:", error);
		}
	},
	// Logs out the current user.
	logOut({ commit }) {
		logEvent(analytics, "logout");

		// eslint-disable-next-line no-unused-vars
		commit("SET_CURRENT_USER", null);
		commit("SET_CLAIMS", null);
		return new Promise((resolve, reject) => {
			// eslint-disable-next-line no-unused-vars
			getFirebaseBackend()
				.logout()
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// register the user
	register({ commit, dispatch, getters }, { username, email, password } = {}) {
		if (getters.loggedIn) return dispatch("validate");

		return getFirebaseBackend()
			.registerUser(username, email, password)
			.then((response) => {
				const user = response;
				commit("SET_CURRENT_USER", user);
				return user;
			});
	},

	// register the user
	// eslint-disable-next-line no-unused-vars
	resetPassword({ commit, dispatch, getters }, { email } = {}) {
		if (getters.loggedIn) return dispatch("validate");

		return getFirebaseBackend()
			.forgetPassword(email)
			.then((response) => {
				const message = response.data;
				return message;
			});
	},
	setupAuthListener({ commit }) {
		getFirebaseBackend().auth.onAuthStateChanged(async (user) => {
			if (user) {
				// User is signed in, refresh the claims
				const claims = await getFirebaseBackend().getCurrentUserClaims();
				commit("SET_CLAIMS", claims);
				commit("SET_CURRENT_USER", user);
				localStorage.setItem("emailForSignIn", user.email);

				// Ensure localStorage is also updated
				localStorage.setItem("user", JSON.stringify(user));
				try {
					if (user.email) {
						var userObj = {
							email: user.email,
							id: user.uid,
							username: user.email,
						};
						Sentry.setUser(userObj);
					}
				} catch (e) {
					console.error(e);
				}
				//console.log("User Logged In: ", user, "Changing Claims: ", claims);
			} else {
				// User is signed out, clear the claims and user
				commit("SET_CLAIMS", null);
				commit("SET_CURRENT_USER", null);
				localStorage.removeItem("user");
			}
		});
	},

	// Validates the current user's token and refreshes it
	// with new data from the API.
	// eslint-disable-next-line no-unused-vars
	validate({ commit, state }) {
		if (!state.currentUser) return null;
		try {
			const user = getFirebaseBackend().getAuthenticatedUser();
			commit("SET_CURRENT_USER", user);
			const claims = getFirebaseBackend().getCurrentUserClaims();
			commit("SET_CLAIMS", claims);
			console.log(`Settings claims with validate`)
			try {
				if (user.email) {
					var userObj = {
						email: user.email,
						id: user.uid,
						username: user.email,
					};
					Sentry.setUser(userObj);
				}
			} catch (e) {
				console.error(e);
			}
			return { user, claims, token: {} };
		} catch (error) {
			console.log("Error: ", error);
			return Promise.reject(error);
		}
	},
};

// ===
// Private helpers
// ===

function saveState(key, state) {
	window.sessionStorage.setItem(key, JSON.stringify(state));
}
