<script>
import {findUserInfo} from "@/common/utils/findUserInfo";

import TopBarNotifications from "@/components/widgets/TopBarNotifications.vue";

import {layoutMethods, settingsGetters} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
	components: {
		TopBarNotifications,
	},
	data() {
		return {
			user: {},

			text: null,
			flag: null,
			value: null,
			myVar: 1,
		};
	},

	computed: {
		...settingsGetters,

		displayName() {
			console.log(this.currentUser);
			// You can modify this logic based on the structure of your user object
			return this.currentUser ? this.currentUser.displayName : "";
		},
		avatarPicture() {
			// You can modify this logic based on the structure of your user object
			return this.currentUser ? this.currentUser.providerData.photoUrl : "";
		},
	},

	mounted() {
		document.addEventListener("scroll", function () {
			var pageTopbar = document.getElementById("page-topbar");
			if (pageTopbar) {
				document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add("topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
			}
		});
		if (document.getElementById("topnav-hamburger-icon")) document.getElementById("topnav-hamburger-icon").addEventListener("click", this.toggleHamburgerMenu);
	},

	methods: {
		...layoutMethods,
		findUserInfo,

		toggleHamburgerMenu() {
			var windowSize = document.documentElement.clientWidth;
			let layoutType = document.documentElement.getAttribute("data-layout");

			document.documentElement.setAttribute("data-sidebar-visibility", "show");
			let visiblilityType = document.documentElement.getAttribute("data-sidebar-visibility");

			if (windowSize > 767) document.querySelector(".hamburger-icon").classList.toggle("open");

			//For collapse horizontal menu
			if (document.documentElement.getAttribute("data-layout") === "horizontal") {
				document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
			}

			//For collapse vertical menu

			if (visiblilityType === "show" && (layoutType === "vertical" || layoutType === "semibox")) {
				if (windowSize < 1025 && windowSize > 767) {
					document.body.classList.remove("vertical-sidebar-enable");
					document.documentElement.getAttribute("data-sidebar-size") == "sm" ? document.documentElement.setAttribute("data-sidebar-size", "") : document.documentElement.setAttribute("data-sidebar-size", "sm");
				} else if (windowSize > 1025) {
					document.body.classList.remove("vertical-sidebar-enable");
					document.documentElement.getAttribute("data-sidebar-size") == "lg" ? document.documentElement.setAttribute("data-sidebar-size", "sm") : document.documentElement.setAttribute("data-sidebar-size", "lg");
				} else if (windowSize <= 767) {
					document.body.classList.add("vertical-sidebar-enable");
					document.documentElement.setAttribute("data-sidebar-size", "lg");
				}
			}

			//Two column menu
			if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
				document.body.classList.contains("twocolumn-panel") ? document.body.classList.remove("twocolumn-panel") : document.body.classList.add("twocolumn-panel");
			}
		},
		toggleMenu() {
			this.$parent.toggleMenu();
		},
		toggleRightSidebar() {
			this.$parent.toggleRightSidebar();
		},
		initFullScreen() {
			document.body.classList.toggle("fullscreen-enable");
			if (
				!document.fullscreenElement &&
				/* alternative standard method */
				!document.mozFullScreenElement &&
				!document.webkitFullscreenElement
			) {
				// current working methods
				if (document.documentElement.requestFullscreen) {
					document.documentElement.requestFullscreen();
				} else if (document.documentElement.mozRequestFullScreen) {
					document.documentElement.mozRequestFullScreen();
				} else if (document.documentElement.webkitRequestFullscreen) {
					document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
				}
			} else {
				if (document.cancelFullScreen) {
					document.cancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				}
			}
		},

		toggleDarkMode() {
			if (document.documentElement.getAttribute("data-bs-theme") == "dark") {
				document.documentElement.setAttribute("data-bs-theme", "light");
			} else {
				document.documentElement.setAttribute("data-bs-theme", "dark");
			}

			const mode = document.documentElement.getAttribute("data-bs-theme");
			this.changeMode({
				mode: mode,
			});
		},
	},
};
</script>

<template>
	<header id="page-topbar">
		<div class="layout-width">
			<div class="navbar-header">
				<div class="d-flex">
					<!-- LOGO -->
					<div class="navbar-brand-box horizontal-logo">
						<router-link to="/" class="logo logo-dark">
							<span class="logo-sm">
								<img src="@/assets/images/logo-sm.png" alt="" height="22" />
							</span>
							<span class="logo-lg">
								<img src="@/assets/images/logo-dark.png" alt="" height="17" />
							</span>
						</router-link>

						<router-link to="/" class="logo logo-light">
							<span class="logo-sm">
								<img src="@/assets/images/logo-sm.png" alt="" height="22" />
							</span>
							<span class="logo-lg">
								<img src="@/assets/images/logo-light.png" alt="" height="17" />
							</span>
						</router-link>
					</div>

					<button id="topnav-hamburger-icon" type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger">
						<span class="hamburger-icon">
							<span></span>
							<span></span>
							<span></span>
						</span>
					</button>

					<!-- App Search-->
				</div>

				<div class="d-flex align-items-center">
					<div class="ms-1 header-item d-none d-sm-flex">
						<BButton type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle" data-toggle="fullscreen" @click="initFullScreen">
							<i class="bx bx-fullscreen fs-22"></i>
						</BButton>
					</div>

					<div v-if="1 == 2" class="ms-1 header-item d-none d-sm-flex">
						<BButton type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle light-dark-mode" @click="toggleDarkMode">
							<i class="bx bx-moon fs-22"></i>
						</BButton>
					</div>

					<TopBarNotifications />

					<BDropdown variant="link" class="ms-sm-3 header-item topbar-user" toggle-class="rounded-circle arrow-none" menu-class="dropdown-menu-end" :offset="{alignmentAxis: -14, crossAxis: 0, mainAxis: 0}">
						<template #button-content>
							<span class="d-flex align-items-center">
								<img class="rounded-circle header-profile-user" :src="findUserInfo(currentUser).photoURL" alt="Header Avatar" />
								<span class="text-start ms-xl-2">
									<span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ findUserInfo(currentUser).displayName }}</span>
									<span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{{ findUserInfo(currentUser).email }}</span>
								</span>
							</span>
						</template>
						<h6 v-if="currentUser" class="dropdown-header">Welcome {{ findUserInfo(currentUser).displayName ? findUserInfo(currentUser).displayName.toString().split(" ")[0] : "" }}!</h6>
						<router-link v-if="1 == 2" class="dropdown-item" to="#">
							<i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
							<span class="align-middle">Profile</span>
						</router-link>
						<router-link v-if="1 == 2" class="dropdown-item" to="#">
							<i class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
							<span class="align-middle">Messages</span>
						</router-link>
						<router-link class="dropdown-item" to="/account/tasks">
							<i class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>
							<span class="align-middle">Taskboard</span>
						</router-link>

						<router-link v-if="1 == 2" class="dropdown-item" to="#">
							<BBadge variant="success-subtle" class="bg-success-subtle text-success mt-1 float-end">New</BBadge>
							<i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
							<span class="align-middle">Settings</span>
						</router-link>

						<router-link class="dropdown-item" to="/logout">
							<i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
							<span class="align-middle" data-key="t-logout">Logout</span>
						</router-link>
					</BDropdown>
				</div>
			</div>
		</div>
	</header>
</template>
