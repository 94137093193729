// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAdditionalUserInfo ,getAuth, GoogleAuthProvider, sendEmailVerification,sendPasswordResetEmail, signInWithEmailAndPassword,signInWithPopup, signOut, updateProfile } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // If you're using Firestore

class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            const app = initializeApp(firebaseConfig);
            this.auth = getAuth(app);
            this.firestore = getFirestore(app); // If you're using Firestore

            this.auth.onAuthStateChanged((user) => {
                if (user) {
                    // sessionStorage.setItem("authUser", JSON.stringify(user));
                } else {
                    sessionStorage.removeItem("authUser");
                }
            });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser(username, email, password) {
        return new Promise((resolve, reject) => {
            if(email.includes("@gustinquon.com")){
                reject("You must login with your Google account. Please click on the Google button to login.");
                return;
            }
            createUserWithEmailAndPassword(this.auth, email, password)
                .then(async (userCredential) => {
                    
                    await updateProfile(userCredential.user, {
                        displayName: username,
                    })
                    sendEmailVerification(userCredential.user).then(() => {
                        
                 resolve({user:userCredential.user,status:"Email verification sent to your email address"});
                    });
                })
                .catch((error) => {
                    reject(_handleError(error));
                });
        });
    }

    /**
     * Login user with Google
     */
    loginUserGoogle(email=null) {
        const provider = new GoogleAuthProvider();
        console.log(email)

        if(email&&email!=""){
            provider.setCustomParameters({
                'login_hint': email
              });
        }
        return signInWithPopup(this.auth, provider)
            .then((result) => {
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				console.log(`token: ${token}`)
                const user = result.user; // The signed-in user info.
                const { isNewUser } = getAdditionalUserInfo(result)                    
                sessionStorage.setItem("authUser", JSON.stringify(user));
                localStorage.setItem("user", JSON.stringify(user));
                return { user, token,isNewUser };
            })
            .catch((error) => {
                console.log("authUtils.js loginUser error: ", error);
                throw error;
            });
    }
    loginUser(email, password) {
        return signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          sessionStorage.setItem("authUser", JSON.stringify(user));
          localStorage.setItem("user", JSON.stringify(user));
          return {user,token:{}}
          // ...
        }).catch((error) => {
            console.log("authUtils.js loginUser error: ", error);
            throw error;
            });

    }
    getCurrentUserClaims() {
        return new Promise((resolve, reject) => {
            const user = this.auth.currentUser;
            if (user) {
                user.getIdTokenResult(true)
                    .then((idTokenResult) => {
                        resolve(idTokenResult.claims);
                    })
                    .catch((error) => {
                        reject(_handleError(error));
                    });
            } else {
                reject("No user is currently logged in.");
            }
        });
    }

    forgetPassword(email) {
        return new Promise((resolve, reject) => {
            sendPasswordResetEmail(this.auth, email, {url: window.location.protocol + "//" + window.location.host + "/login"})
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(_handleError(error));
                });
        });
    }

    logout() {
        return new Promise((resolve, reject) => {
            signOut(this.auth)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(_handleError(error));
                });
        });
    }

    setLoggeedInUser(user) {
        sessionStorage.setItem("authUser", JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
    }

    getAuthenticatedUser() {
        if (!sessionStorage.getItem("authUser")) return null;
        return JSON.parse(sessionStorage.getItem("authUser"));
    }

  
    
}
function   _handleError(error) {
    var errorMessage = error.message;
    return errorMessage;
}
let _fireBaseBackend = null;

const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
};

const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export { getFirebaseBackend,initFirebaseBackend };