import { mapActions,mapGetters, mapState } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    currentClaims: (state) => state.currentClaims,
  }),
  ...mapGetters('auth', ['loggedIn','claims']),

}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    sidebarSize: (state) => state.sidebarSize,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    mode: (state) => state.mode,
    position: (state) => state.position,
    sidebarView: (state) => state.sidebarView,
    sidebarColor: (state) => state.sidebarColor,
    sidebarImage: (state) => state.sidebarImage,
    visibility: (state) => state.visibility
  })
}

export const authMethods = mapActions('auth', ['logIn','logInGoogle', 'logOut', 'register', 'resetPassword','setupAuthListener'])

export const layoutMethods = mapActions('layout', 
['changeLayoutType', 'changeLayoutWidth', 'changeSidebarSize', 'changeTopbar', 'changeMode', 'changePosition', 'changeSidebarView',
 'changeSidebarColor','changeSidebarImage','changePreloader', 'changeVisibility'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const settingsComputed = {
  ...mapState('settings', {
    settings: (state) => state.settings
  })
}
export const usersComputed = {
  ...mapState('users', {
    users: (state) => state.users
  })
}
export const settingMethods = mapActions('settings', ['fetchSettings'])
export const usersMethods = mapActions('users', ['fetchAllUsers'])

export const settingsGetters = {...mapGetters('settings', ['settings']),...mapGetters('users', ['users']),...mapGetters('auth', ['claims']),... mapState('auth', {
  currentUser: (state) => state.currentUser,})};