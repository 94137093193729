<script setup>
import {defineEmits, defineProps} from "vue";

import Editor from "primevue/editor";

const props = defineProps({
	modelValue: {
		type: String,
		default: "",
	},
});

const emits = defineEmits(["update:modelValue"]);

const onLoad = ({instance}) => {
	instance.setContents(
		instance.clipboard.convert({
			html: props.modelValue,
		}),
	);
};

const onChange = (v) => {
	emits("update:modelValue", v);
};
</script>

<template>
	<Editor editor-style="height: 20em" @load="onLoad" @update:modelValue="onChange"></Editor>
</template>
