<template>
	<li class="nav-item">
		<a class="nav-link menu-link sales-email-link" @click="handleClick">
			<i class="mdi mdi-email-fast"></i>
			<span data-key="t-base-ui">Send Sales Email</span>
		</a>
	</li>
</template>

<script>
import Swal from "sweetalert2";

import EmailsDataService from "@/common/data/firestore/emails";

export default {
	components: {},

	data() {
		return {
			loadingSend: false,
			loading: false,
		};
	},
	computed: {
		userClaims() {
			return this.$store.getters["auth/userClaims"];
		},
	},
	created() {
		this.user = JSON.parse(localStorage.getItem("user"));
	},
	methods: {
		async handleClick() {
			try {
				this.$store.dispatch("auth/trackInteraction", {
					userId: this.$store.state.auth.currentUser.uid,
					featureId: "clicked-send-sales-email-button",
				});
			} catch (e) {
				console.error(e);
			}
			// Handle button click event here
			this.loading = true;
			try {
				//SWAL.fire asking for email address to send the presentation to
				const {value: email} = await Swal.fire({
					title: "Send Example Training Document Info Sheet",
					text: "Enter the email address to send the example document to",
					input: "email",
					inputLabel: "Email Address",
					inputPlaceholder: "Enter email address",
					confirmButtonText: "Send",
					showCancelButton: true,
				});
				if (email) {
					await this.handleSendEmail(email);
					Swal.fire({
						title: "Document Sent!",
						text: "The document has been sent to the email address provided",
						icon: "success",
						confirmButtonText: "OK",
					});
				}
				this.loading = false;
			} catch (e) {
				Swal.fire({
					title: "Error Sending Document!",
					text: e.message,
					icon: "error",
					confirmButtonText: "OK",
				});
				this.loading = false;
			}
		},

		async handleSendEmail(email) {
			//Send email by creating a new mail record in firestore it should cc the current user and all emails in the contacts
			//Send email to all contacts
			let emailData = {
				to: email,
				cc: [this.user.email],

				template: {
					name: "sendExampleTrainingEmail",
					data: {
						user: this.user,
					},
				},
				createdBy: this.user.uid,
				createdAt: new Date(),
			};
			// if (this.user.email.includes("gustinquon.com")) {
			// 	emailData.from = this.user.email;
			// }
			emailData.from = `granthelp@grantsuccesses.com`;
			await EmailsDataService.create(emailData);
		},
	},
};
</script>

<style>
.sales-email-link {
	cursor: pointer;
}
</style>
