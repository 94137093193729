/* eslint-disable no-unused-vars */
import {createApp} from "vue";
import VueFeather from "vue-feather";
import VueApexCharts from "vue3-apexcharts";
import {firestoreDefaultConverter, globalFirestoreOptions,VueFire, VueFireAuth, VueFireFirestoreOptionsAPI} from "vuefire";

import * as Sentry from "@sentry/vue";
import AOS from "aos";
import BootstrapVueNext from "bootstrap-vue-next";
import vClickOutside from "click-outside-vue3";
import {vMaska} from "maska";

import PrimeVue from "primevue/config";
import PrimeOne from "primevue/themes/primeone";
import Aura from "primevue/themes/primeone/aura";
import Tooltip from "primevue/tooltip";

import {firebaseApp} from "@/common/data/firestore/firebase";

import "bootstrap/dist/js/bootstrap.bundle";

import store from "./state/store";
import App from "./App.vue";
// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();
// Firebase authentication
import {initFirebaseBackend} from "./authUtils";
import router from "./router";

import "aos/dist/aos.css";
import "@/assets/scss/config/saas/app.scss";
import "@/assets/scss/mermaid.min.css";

globalFirestoreOptions.converter = {
	// the default converter just returns the data: (data) => data
	toFirestore: firestoreDefaultConverter.toFirestore,
	fromFirestore: (snapshot, options) => {
		const data = firestoreDefaultConverter.fromFirestore(snapshot, options);
		// if the document doesn't exist, return null
		if (!data) return null;
		// add anything custom to the returned object
		data.metadata = snapshot.metadata;
		return data;
	},
};
const firebaseConfig = {
	apiKey: "AIzaSyCfxItvYyRPIXQg022EJaGtmzg6F8g5Dwc",
	authDomain: "customer-app-45f18.firebaseapp.com",
	projectId: "customer-app-45f18",
	storageBucket: "customer-app-45f18",
	messagingSenderId: "1009883748677",
	appId: "1:1009883748677:web:88341d09cdd47b10ff062c",
	measurementId: "G-XMHWDMNLMX",
};

// Execute the initialization function
initFirebaseBackend(firebaseConfig);
const app = createApp(App);
console.log("NODE_ENV", process.env.NODE_ENV);
if (process.env.NODE_ENV !== "development") {
	Sentry.init({
		app,
		dsn: "https://b3eb75acf2a2b4dcc491b1d78e6acca4@o4505987591110656.ingest.us.sentry.io/4506983890944000",
		trackComponents: true,
		integrations: [
			Sentry.browserTracingIntegration({router}),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
				maskAllInputs: false,
			}),
		],
		tracingOptions: {
			trackComponents: true,
		},
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost"],
		// Session Replay
		replaysSessionSampleRate: 0, // This sets the sample rate at 100%. You may want to change it to a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		attachProps: true,
		attachStacktrace: true,

	});
}

// AOS initialization
AOS.init({
	easing: "ease-out-back",
	duration: 1000,
});

// Fetch users and wait for them to be loaded
store.dispatch("auth/setupAuthListener");

// Create Vue app

app.use(VueFire, {
	// imported above but could also just be created here
	firebaseApp,
	modules: [
		// we will see other modules later on
		VueFireAuth(),
		VueFireFirestoreOptionsAPI(),
	],
});

// Use plugins and components
app.use(store);
app.use(router);

app.use(VueApexCharts);
app.use(BootstrapVueNext);

app.directive("tooltip", Tooltip);
app.use(PrimeVue, {
	// Default theme configuration
	theme: {
		base: PrimeOne,
		preset: Aura,
		options: {
			prefix: "p",
			darkModeSelector: "data-bs-theme",
			cssLayer: false,
		},
	},
});
app.component(VueFeather.name, VueFeather);
app.directive("maska", vMaska);
app.use(vClickOutside);

// Global error handler
app.config.errorHandler = (err, instance, info) => {
	Sentry.captureException(err);
	console.error("Global error:", err);
	console.log("Vue instance:", instance);
	console.log("Error info:", info);
};

// Mount the app
app.mount("#app");
