import {getCurrentUser} from "vuefire";
import {getIdTokenResult} from "firebase/auth";
import {collection, doc, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore";

import {settingsCollection} from "../firebase";

class SettingsDataService {
	// Retrieve all meetings for a specific deal by its uniqueId
	async getAll() {
		const idTokenResult = await getIdTokenResult(await getCurrentUser());
		const claims = idTokenResult.claims;
		console.log(claims);

		var rules = [
			{docId: "training", subCollections: ["trainingCompanies"], permissions: ["p_viewDeals"]},
			{docId: "meetings", subCollections: ["calendly-event-types"], permissions: ["p_viewEventTypes"]},
			{docId: "users", subCollections: [], permissions: ["p_manageUsers"]},
			{docId: "referralSources", subCollections: [], permissions: ["p_viewReferralSources"]},
			{docId: "company", subCollections: [], permissions: ["p_viewCompanies"]},
			{docId: "deals", subCollections: [], permissions: ["p_viewDeals"]},
			{docId: "grants", subCollections: [], permissions: ["p_viewGrants"]},
		];

		const querySnapshot = await getDocs(settingsCollection);
		//console.log(querySnapshot.docs.map((doc) => doc.data()));
		let settings = {};
		await Promise.all(
			querySnapshot.docs.map(async (doc) => {
				var subCollections = rules.filter((rule) => rule.permissions.some((permission) => Object.prototype.hasOwnProperty.call(claims, permission) && claims[permission] === true));
				//console.log("subCollections", subCollections);
				//console.log("claims", claims);
				let settingData = {...doc.data(), id: doc.id};
				//console.log("settingData", settingData);
				// Check if subcollections need to be fetched
				let subCollectionsFiltered = subCollections.find((subCollection) => subCollection.docId === settingData.id);
				if (subCollectionsFiltered && subCollectionsFiltered.subCollections.length > 0) {
					for (let subCollectionName of subCollectionsFiltered.subCollections) {
						const subCollectionRef = collection(doc.ref, subCollectionName);
						const subQuerySnapshot = await getDocs(subCollectionRef);
						settingData[subCollectionName] = subQuerySnapshot.docs.map((subDoc) => ({
							...subDoc.data(),
							id: subDoc.id,
						}));
					}
				}
				settings[doc.id] = settingData;
			}),
		);

		return settings;
	}

	// Retrieve a specific meeting document by deal's uniqueId and meeting's id
	async getDocument(dataId) {
		const docRef = doc(settingsCollection, dataId);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			return {...docSnap.data(), id: docSnap.id};
		} else {
			console.log("No such document!");
		}
	}

	// Create a new meeting under a deal identified by uniqueId
	async create(dataId, meetingData) {
		const docRef = doc(settingsCollection, dataId);
		return await setDoc(docRef, meetingData);
	}

	// Update an existing meeting's data by deal's uniqueId and meeting's id
	async update(dataId, value) {
		const docRef = doc(settingsCollection, dataId);
		return await updateDoc(docRef, value);
	}
}

export default new SettingsDataService();
