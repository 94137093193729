<script>
import simpleBar from "simplebar-vue";

import {layoutComputed, layoutMethods} from "@/state/helpers";

localStorage.setItem("rightbar_isopen", false);
/**
 * Right sidebar component
 */

export default {
	components: {simpleBar},
	data() {
		return {
			show: false,
			showGradients: false,
			resetLayoutMode: {},
		};
	},
	computed: {
		...layoutComputed,
		layoutType: {
			get() {
				return this.$store ? this.$store.state.layout.layoutType : {} || {};
			},
			set(layout) {
				localStorage.setItem("rightbar_isopen", true);
				this.changeLayoutType({layoutType: layout});
				document.querySelector(".hamburger-icon").classList.remove("open");
			},
		},
		preloader: {
			get() {
				return this.$store ? this.$store.state.layout.preloader : {} || {};
			},
			set(preloader) {
				return this.changePreloader({
					preloader: preloader,
				});
			},
		},
		mode: {
			get() {
				return this.$store ? this.$store.state.layout.mode : {} || {};
			},
			set(mode) {
				if (mode == "dark") {
					this.changeMode({mode: mode});
					this.changeTopbar({topbar: "light"});
				} else {
					this.changeMode({mode: mode});
					this.changeTopbar({topbar: "light"});
				}
			},
		},
		sidebarSize: {
			get() {
				return this.$store ? this.$store.state.layout.sidebarSize : {} || {};
			},
			set(type) {
				return this.changeSidebarSize({
					sidebarSize: type,
				});
			},
		},
		layoutWidth: {
			get() {
				return this.$store ? this.$store.state.layout.layoutWidth : {} || {};
			},
			set(width) {
				if (width == "boxed") {
					this.changeLayoutWidth({layoutWidth: width});
					this.changeSidebarSize({sidebarSize: "sm-hover"});
				} else {
					this.changeLayoutWidth({layoutWidth: width});
					this.changeSidebarSize({sidebarSize: "lg"});
				}
			},
		},
		position: {
			get() {
				return this.$store ? this.$store.state.layout.position : {} || {};
			},
			set(position) {
				return this.changePosition({
					position: position,
				});
			},
		},
		topbar: {
			get() {
				return this.$store ? this.$store.state.layout.topbar : {} || {};
			},
			set(topbar) {
				this.changeTopbar({
					topbar: topbar,
				});
			},
		},
		sidebarView: {
			get() {
				return this.$store ? this.$store.state.layout.sidebarView : {} || {};
			},
			set(sidebarView) {
				return this.changeSidebarView({
					sidebarView: sidebarView,
				});
			},
		},
		sidebarColor: {
			get() {
				return this.$store ? this.$store.state.layout.sidebarColor : {} || {};
			},
			set(sidebarColor) {
				console.log("this.$store.state.layout.sidebarColor", this.$store.state.layout.sidebarColor);
				return this.changeSidebarColor({
					sidebarColor: sidebarColor,
				});
			},
		},
		sidebarImage: {
			get() {
				return this.$store ? this.$store.state.layout.sidebarImage : {} || {};
			},
			set(sidebarImage) {
				return this.changeSidebarImage({
					sidebarImage: sidebarImage,
				});
			},
		},

		visibility: {
			get() {
				return this.$store ? this.$store.state.layout.visibility : {} || {};
			},
			set(visibility) {
				if (visibility == "hidden") {
					document.querySelector(".hamburger-icon").classList.add("open");
				} else {
					document.querySelector(".hamburger-icon").classList.remove("open");
				}
				this.changeVisibility({
					visibility: visibility,
				});
			},
		},
	},

	watch: {
		mode: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "dark":
							document.documentElement.setAttribute("data-bs-theme", "dark");
							break;
						case "light":
							document.documentElement.setAttribute("data-bs-theme", "light");
							break;
					}
				}
			},
		},
		preloader: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "enable":
							document.documentElement.setAttribute("data-preloader", "enable");
							break;
						case "disable":
							document.documentElement.setAttribute("data-preloader", "disable");
							break;
					}
					localStorage.setItem("data-preloader", newVal);
				}
			},
		},
		layoutType: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "horizontal":
							document.documentElement.setAttribute("data-layout", "horizontal");
							break;
						case "vertical":
							document.documentElement.setAttribute("data-layout", "vertical");
							break;
						case "twocolumn":
							document.documentElement.setAttribute("data-layout", "twocolumn");
							break;
						case "semibox":
							document.documentElement.setAttribute("data-layout", "semibox");
							break;
					}
				}
			},
		},
		layoutWidth: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "fluid":
							document.documentElement.setAttribute("data-layout-width", "fluid");
							break;
						case "boxed":
							document.documentElement.setAttribute("data-layout-width", "boxed");
							break;
					}
				}
			},
		},
		position: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "fixed":
							document.documentElement.setAttribute("data-layout-position", "fixed");
							break;
						case "scrollable":
							document.documentElement.setAttribute("data-layout-position", "scrollable");
							break;
					}
				}
			},
		},
		topbar: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "light":
							document.documentElement.setAttribute("data-topbar", "light");
							break;
						case "dark":
							document.documentElement.setAttribute("data-topbar", "dark");
							break;
					}
				}
			},
		},
		sidebarSize: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "lg":
							document.documentElement.setAttribute("data-sidebar-size", "lg");
							break;
						case "sm":
							document.documentElement.setAttribute("data-sidebar-size", "sm");
							break;
						case "md":
							document.documentElement.setAttribute("data-sidebar-size", "md");
							break;
						case "sm-hover":
							document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
							break;
					}
				}
			},
		},
		sidebarView: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "detached":
							document.documentElement.setAttribute("data-layout-style", "detached");
							break;
						case "default":
							document.documentElement.setAttribute("data-layout-style", "default");
							break;
					}
				}
			},
		},
		sidebarColor: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "dark":
							document.documentElement.setAttribute("data-sidebar", "dark");
							break;
						case "light":
							document.documentElement.setAttribute("data-sidebar", "light");
							break;
						case "gradient":
							document.documentElement.setAttribute("data-sidebar", "gradient");
							break;
						case "gradient-2":
							document.documentElement.setAttribute("data-sidebar", "gradient-2");
							break;
						case "gradient-3":
							document.documentElement.setAttribute("data-sidebar", "gradient-3");
							break;
						case "gradient-4":
							document.documentElement.setAttribute("data-sidebar", "gradient-4");
							break;
					}
				}
			},
		},
		sidebarImage: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "img-1":
							document.documentElement.setAttribute("data-sidebar-image", "img-1");
							break;
						case "img-2":
							document.documentElement.setAttribute("data-sidebar-image", "img-2");
							break;
						case "img-3":
							document.documentElement.setAttribute("data-sidebar-image", "img-3");
							break;
						case "img-4":
							document.documentElement.setAttribute("data-sidebar-image", "img-4");
							break;
						case "none":
							document.documentElement.setAttribute("data-sidebar-image", "none");
							break;
					}
				}
			},
		},
		visibility: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					switch (newVal) {
						case "show":
							document.documentElement.setAttribute("data-sidebar-visibility", "show");
							break;
						case "hidden":
							document.documentElement.setAttribute("data-sidebar-visibility", "hidden");
							break;
					}
				}
			},
		},
	},
	beforeCreate() {
		//console.log("this.resetLayoutMode", (this.resetLayoutMode = this.$store.state.layout));
		//localStorage.setItem("resetValue", JSON.stringify(this.$store.state.layout));
		localStorage.setItem("resetValue", JSON.stringify(this.$store.state.layout));
	},
	mounted() {
		var setpreloader = document.getElementById("preloader");
		if (localStorage.getItem("data-preloader") && localStorage.getItem("data-preloader") == "enable") {
			document.documentElement.setAttribute("data-preloader", "enable");
			if (setpreloader) {
				setTimeout(function () {
					setpreloader.style.opacity = "0";
					setpreloader.style.visibility = "hidden";
				}, 1000);
			}
		} else {
			document.documentElement.setAttribute("data-preloader", "disable");
			if (setpreloader) {
				setpreloader.style.opacity = "0";
				setpreloader.style.visibility = "hidden";
			}
		}
		if (document.getElementById("collapseBgGradient")) {
			Array.from(document.querySelectorAll("#collapseBgGradient .form-check input")).forEach(function () {
				if (document.querySelector("[data-bs-target='#collapseBgGradient']")) {
					document.querySelector("[data-bs-target='#collapseBgGradient']").addEventListener("click", function () {
						document.getElementById("sidebar-color-gradient").click();
					});
				}
			});
			Array.from(document.querySelectorAll("[name='data-sidebar']")).forEach(function (elem) {
				if (document.querySelector("[data-bs-target='#collapseBgGradient']")) {
					if (document.querySelector("#collapseBgGradient .form-check input:checked")) {
						document.querySelector("[data-bs-target='#collapseBgGradient']").classList.add("active");
					} else {
						document.querySelector("[data-bs-target='#collapseBgGradient']").classList.remove("active");
						document.getElementById("collapseBgGradient").classList.remove("show");
					}

					elem.addEventListener("change", function () {
						if (document.querySelector("#collapseBgGradient .form-check input:checked")) {
							document.querySelector("[data-bs-target='#collapseBgGradient']").classList.add("active");
						} else {
							document.getElementById("collapseBgGradient").classList.remove("show");
							document.querySelector("[data-bs-target='#collapseBgGradient']").classList.remove("active");
						}
					});
				}
			});
		}
	},

	methods: {
		...layoutMethods,
		click() {
			this.show = !this.show;
		},
		topFunction() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		},

		resizeWindow() {
			var windowSize = document.documentElement.clientWidth;
			if (windowSize >= 1025) {
				if (document.documentElement.getAttribute("data-layout") === "vertical") {
					document.documentElement.setAttribute("data-sidebar-size", this.$store.state.layout.sidebarSize);
				}
				if (document.documentElement.getAttribute("data-layout") === "semibox") {
					document.documentElement.setAttribute("data-sidebar-size", this.$store.state.layout.sidebarSize);
				}
				if (document.documentElement.getAttribute("data-sidebar-visibility") === "show" && document.querySelector(".hamburger-icon")) {
					document.querySelector(".hamburger-icon").classList.remove("open");
				}
			} else if (windowSize < 1025 && windowSize > 767) {
				document.body.classList.remove("twocolumn-panel");
				if (document.documentElement.getAttribute("data-layout") === "vertical") {
					document.documentElement.setAttribute("data-sidebar-size", "sm");
				}
				if (document.documentElement.getAttribute("data-layout") === "semibox") {
					document.documentElement.setAttribute("data-sidebar-size", "sm");
				}
				if (document.querySelector(".hamburger-icon")) {
					document.querySelector(".hamburger-icon").classList.add("open");
				}
			} else if (windowSize <= 767) {
				document.body.classList.remove("vertical-sidebar-enable");
				document.body.classList.add("twocolumn-panel");
				if (document.documentElement.getAttribute("data-layout") !== "horizontal") {
					document.documentElement.setAttribute("data-sidebar-size", "lg");
				}
				if (document.querySelector(".hamburger-icon")) {
					document.querySelector(".hamburger-icon").classList.add("open");
				}
			}
		},

		resetLayout() {
			let reset = JSON.parse(localStorage.getItem("resetValue"));
			document.documentElement.setAttribute("data-sidebar-size", "lg");
			this.changeMode({mode: reset.mode});
			this.changeSidebarColor({sidebarColor: reset.sidebarColor});
			this.changeLayoutType({layoutType: reset.layoutType});
			this.changeTopbar({topbar: reset.topbar});
			this.changeLayoutWidth({layoutWidth: reset.layoutWidth});
			this.changeSidebarSize({sidebarSize: reset.sidebarSize});
			this.changeSidebarImage({sidebarImage: reset.sidebarImage});
			this.changeSidebarColor({sidebarColor: reset.sidebarColor});
			this.changePreloader({preloader: reset.preloader});
			this.changeSidebarView({sidebarView: reset.sidebarView});
			this.changeVisibility({visibility: reset.visibility});
			this.changePosition({position: reset.position});
		},

		gradiantColor() {
			this.changeSidebarColor({sidebarColor: "gradient"});
		},

		onSideBarColorClick(color) {
			if (color !== "gradient") {
				this.showGradients = false;
			} else {
				this.showGradients = true;
				this.gradiantColor();
			}
		},
	},
};
</script>

<template>
	<div>
		<div id="preloader">
			<div id="status">
				<div class="spinner-border text-primary avatar-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>

		<!---<div class="customizer-setting d-none d-md-block" @click="click">
      <div class="btn-info rounded-pill shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas" id="mdi-cog">
        <i class="mdi mdi-spin mdi-cog-outline fs-22"></i>
      </div>
    </div>-->
		<BOffcanvas id="theme-settings-offcanvas" v-model="show" class="border-0" header-class="d-flex align-items-center bg-primary bg-gradient p-3" body-class="p-0" footer-class="border-top p-3 text-center" placement="end">
			<template #header>
				<div class="me-2">
					<h5 class="m-0 me-2 text-white">Theme Customizer</h5>
				</div>
				<button id="customizerclose-btn" type="button" class="btn-close btn-close-white ms-auto" @click="click"></button>
			</template>
			<simpleBar class="h-100">
				<div class="p-4">
					<h6 class="mb-0 fw-bold text-uppercase">Layout</h6>
					<p class="text-muted">Choose your layout</p>

					<BRow class="gy-3">
						<BCol cols="4">
							<div class="form-check card-radio">
								<input id="customizer-layout01" v-model="layoutType" name="data-layout" type="radio" value="vertical" class="form-check-input" />
								<label class="form-check-label p-0 avatar-md w-100" for="customizer-layout01">
									<span class="d-flex gap-1 h-100">
										<span class="flex-shrink-0">
											<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
												<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
											</span>
										</span>
										<span class="flex-grow-1">
											<span class="d-flex h-100 flex-column">
												<span class="bg-light d-block p-1"></span>
												<span class="bg-light d-block p-1 mt-auto"></span>
											</span>
										</span>
									</span>
								</label>
							</div>
							<h5 class="fs-13 text-center mt-2">Vertical</h5>
						</BCol>
						<BCol cols="4">
							<div class="form-check card-radio">
								<input id="customizer-layout02" v-model="layoutType" name="data-layout" type="radio" value="horizontal" class="form-check-input" />
								<label class="form-check-label p-0 avatar-md w-100" for="customizer-layout02">
									<span class="d-flex h-100 flex-column gap-1">
										<span class="bg-light d-flex p-1 gap-1 align-items-center">
											<span class="d-block p-1 bg-primary-subtle rounded me-1"></span>
											<span class="d-block p-1 pb-0 px-2 bg-primary-subtle ms-auto"></span>
											<span class="d-block p-1 pb-0 px-2 bg-primary-subtle"></span>
										</span>
										<span class="bg-light d-block p-1"></span>
										<span class="bg-light d-block p-1 mt-auto"></span>
									</span>
								</label>
							</div>
							<h5 class="fs-13 text-center mt-2">Horizontal</h5>
						</BCol>
						<BCol cols="4">
							<div class="form-check card-radio">
								<input id="customizer-layout03" v-model="layoutType" name="data-layout" type="radio" value="twocolumn" class="form-check-input" />
								<label class="form-check-label p-0 avatar-md w-100" for="customizer-layout03">
									<span class="d-flex gap-1 h-100">
										<span class="flex-shrink-0">
											<span class="bg-light d-flex h-100 flex-column gap-1">
												<span class="d-block p-1 bg-primary-subtle mb-2"></span>
												<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
											</span>
										</span>
										<span class="flex-shrink-0">
											<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
											</span>
										</span>
										<span class="flex-grow-1">
											<span class="d-flex h-100 flex-column">
												<span class="bg-light d-block p-1"></span>
												<span class="bg-light d-block p-1 mt-auto"></span>
											</span>
										</span>
									</span>
								</label>
							</div>
							<h5 class="fs-13 text-center mt-2">Two Column</h5>
						</BCol>
						<BCol cols="4">
							<div class="form-check card-radio">
								<input id="customizer-layout04" v-model="layoutType" name="data-layout" type="radio" value="semibox" class="form-check-input" />
								<label class="form-check-label p-0 avatar-md w-100" for="customizer-layout04">
									<span class="d-flex gap-1 h-100">
										<span class="flex-shrink-0 p-1">
											<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
												<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
											</span>
										</span>
										<span class="flex-grow-1">
											<span class="d-flex h-100 flex-column pt-1 pe-2">
												<span class="bg-light d-block p-1"></span>
												<span class="bg-light d-block p-1 mt-auto"></span>
											</span>
										</span>
									</span>
								</label>
							</div>
							<h5 class="fs-13 text-center mt-2">Semi Box</h5>
						</BCol>
					</BRow>

					<h6 class="mt-4 mb-0 fw-bold text-uppercase">Color Scheme</h6>
					<p class="text-muted">Choose Light or Dark Scheme.</p>

					<div class="colorscheme-cardradio">
						<BRow>
							<BCol cols="4">
								<div class="form-check card-radio">
									<input id="layout-mode-light" v-model="mode" class="form-check-input" type="radio" name="data-bs-theme" value="light" />
									<label class="form-check-label p-0 avatar-md w-100" for="layout-mode-light">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Light</h5>
							</BCol>

							<BCol cols="4">
								<div class="form-check card-radio dark">
									<input id="layout-mode-dark" v-model="mode" class="form-check-input" type="radio" name="data-bs-theme" value="dark" />
									<label class="form-check-label p-0 avatar-md w-100 bg-dark" for="layout-mode-dark">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-white bg-opacity-10 d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-white bg-opacity-10 rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
													<span class="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
													<span class="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-white bg-opacity-10 d-block p-1"></span>
													<span class="bg-white bg-opacity-10 d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Dark</h5>
							</BCol>
						</BRow>
					</div>
					<div v-if="layoutType == 'semibox'" id="sidebar-visibility">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Sidebar Visibility</h6>
						<p class="text-muted">Choose show or Hidden sidebar.</p>

						<BRow>
							<BCol cols="4">
								<div class="form-check card-radio">
									<input id="sidebar-visibility-show" v-model="visibility" class="form-check-input" type="radio" name="data-sidebar-visibility" value="show" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-visibility-show">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0 p-1">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column pt-1 pe-2">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Show</h5>
							</BCol>
							<BCol cols="4">
								<div class="form-check card-radio">
									<input id="sidebar-visibility-hidden" v-model="visibility" class="form-check-input" type="radio" name="data-sidebar-visibility" value="hidden" />
									<label class="form-check-label p-0 avatar-md w-100 px-2" for="sidebar-visibility-hidden">
										<span class="d-flex gap-1 h-100">
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column pt-1 px-2">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Hidden</h5>
							</BCol>
						</BRow>
					</div>

					<div v-if="layoutType == 'vertical' || layoutType == 'horizontal'" id="layout-width">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Layout Width</h6>
						<p class="text-muted">Choose Fluid or Boxed layout.</p>

						<BRow>
							<BCol cols="4">
								<div class="form-check card-radio">
									<input id="layout-width-fluid" v-model="layoutWidth" class="form-check-input" type="radio" name="data-layout-width" value="fluid" />
									<label class="form-check-label p-0 avatar-md w-100" for="layout-width-fluid">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Fluid</h5>
							</BCol>
							<BCol cols="4">
								<div class="form-check card-radio">
									<input id="layout-width-boxed" v-model="layoutWidth" class="form-check-input" type="radio" name="data-layout-width" value="boxed" />
									<label class="form-check-label p-0 avatar-md w-100 px-2" for="layout-width-boxed">
										<span class="d-flex gap-1 h-100 border-start border-end">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Boxed</h5>
							</BCol>
						</BRow>
					</div>

					<div v-if="layoutType != 'twocolumn'" id="layout-position">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Layout Position</h6>
						<p class="text-muted">Choose Fixed or Scrollable Layout Position.</p>

						<div class="btn-group radio" role="group">
							<input id="layout-position-fixed" v-model="position" type="radio" class="btn-check" name="data-layout-position" value="fixed" />
							<label class="btn btn-light w-sm" for="layout-position-fixed">Fixed</label>

							<input id="layout-position-scrollable" v-model="position" type="radio" class="btn-check" name="data-layout-position" value="scrollable" />
							<label class="btn btn-light w-sm ms-0" for="layout-position-scrollable">Scrollable</label>
						</div>
					</div>
					<h6 class="mt-4 mb-0 fw-bold text-uppercase">Topbar Color</h6>
					<p class="text-muted">Choose Light or Dark Topbar Color.</p>

					<BRow>
						<BCol cols="4">
							<div class="form-check card-radio">
								<input id="topbar-color-light" v-model="topbar" class="form-check-input" type="radio" name="data-topbar" value="light" />
								<label class="form-check-label p-0 avatar-md w-100" for="topbar-color-light">
									<span class="d-flex gap-1 h-100">
										<span class="flex-shrink-0">
											<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
												<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>

												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
											</span>
										</span>
										<span class="flex-grow-1">
											<span class="d-flex h-100 flex-column">
												<span class="bg-light d-block p-1"></span>
												<span class="bg-light d-block p-1 mt-auto"></span>
											</span>
										</span>
									</span>
								</label>
							</div>
							<h5 class="fs-13 text-center mt-2">Light</h5>
						</BCol>
						<BCol cols="4">
							<div class="form-check card-radio">
								<input id="topbar-color-dark" v-model="topbar" class="form-check-input" type="radio" name="data-topbar" value="dark" />
								<label class="form-check-label p-0 avatar-md w-100" for="topbar-color-dark">
									<span class="d-flex gap-1 h-100">
										<span class="flex-shrink-0">
											<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
												<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>

												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
											</span>
										</span>
										<span class="flex-grow-1">
											<span class="d-flex h-100 flex-column">
												<span class="bg-primary d-block p-1"></span>
												<span class="bg-light d-block p-1 mt-auto"></span>
											</span>
										</span>
									</span>
								</label>
							</div>
							<h5 class="fs-13 text-center mt-2">Dark</h5>
						</BCol>
					</BRow>
					<div v-if="layoutType == 'vertical' || (layoutType == 'semibox' && visibility == 'show')" id="sidebar-size">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Sidebar Size</h6>
						<p class="text-muted">Choose a size of Sidebar.</p>

						<BRow>
							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-size-default" v-model="sidebarSize" class="form-check-input" type="radio" name="data-sidebar-size" value="lg" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-default">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>

													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Default</h5>
							</BCol>

							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-size-compact" v-model="sidebarSize" class="form-check-input" type="radio" name="data-sidebar-size" value="md" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-compact">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 bg-primary-subtle rounded mb-2"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Compact</h5>
							</BCol>

							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-size-small" v-model="sidebarSize" class="form-check-input" type="radio" name="data-sidebar-size" value="sm" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-small">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1">
													<span class="d-block p-1 bg-primary-subtle mb-2"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Small (Icon View)</h5>
							</BCol>

							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-size-small-hover" v-model="sidebarSize" class="form-check-input" type="radio" name="data-sidebar-size" value="sm-hover" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-small-hover">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1">
													<span class="d-block p-1 bg-primary-subtle mb-2"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Small Hover View</h5>
							</BCol>
						</BRow>
					</div>

					<div v-if="layoutType == 'vertical'" id="sidebar-view">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Sidebar View</h6>
						<p class="text-muted">Choose Default or Detached Sidebar view.</p>

						<BRow>
							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-view-default" v-model="sidebarView" class="form-check-input" type="radio" name="data-layout-style" value="default" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-view-default">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>

													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Default</h5>
							</BCol>
							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-view-detached" v-model="sidebarView" class="form-check-input" type="radio" name="data-layout-style" value="detached" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-view-detached">
										<span class="d-flex h-100 flex-column">
											<span class="bg-light d-flex p-1 gap-1 align-items-center px-2">
												<span class="d-block p-1 bg-primary-subtle rounded me-1"></span>
												<span class="d-block p-1 pb-0 px-2 bg-primary-subtle ms-auto"></span>

												<span class="d-block p-1 pb-0 px-2 bg-primary-subtle"></span>
											</span>
											<span class="d-flex gap-1 h-100 p-1 px-2">
												<span class="flex-shrink-0">
													<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
														<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
														<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
														<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													</span>
												</span>
											</span>
											<span class="bg-light d-block p-1 mt-auto px-2"></span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Detached</h5>
							</BCol>
						</BRow>
					</div>

					<div v-if="layoutType == 'vertical' || layoutType === 'twocolumn' || (layoutType === 'semibox' && visibility === 'show')" id="sidebar-color">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Sidebar Color</h6>
						<p class="text-muted">Choose Ligth or Dark Sidebar Color.</p>

						<BRow>
							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-color-light" v-model="sidebarColor" class="form-check-input" type="radio" name="data-sidebar" value="light" @click="onSideBarColorClick('light')" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-color-light">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>

													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Light</h5>
							</BCol>
							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-color-dark" v-model="sidebarColor" class="form-check-input" type="radio" name="data-sidebar" value="dark" @click="onSideBarColorClick('dark')" />
									<label class="form-check-label p-0 avatar-md w-100" for="sidebar-color-dark">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-primary d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-white bg-opacity-10 rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
													<span class="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
													<span class="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Dark</h5>
							</BCol>

						</BRow>
						<BCollapse v-if="showGradients" id="collapseBgGradient">
							<div class="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-color-gradient" v-model="sidebarColor" class="form-check-input" type="radio" name="data-sidebar" value="gradient" />
									<label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient">
										<span class="avatar-title rounded-circle bg-vertical-gradient"></span>
									</label>
								</div>
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-color-gradient-2" v-model="sidebarColor" class="form-check-input" type="radio" name="data-sidebar" value="gradient-2" />
									<label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient-2">
										<span class="avatar-title rounded-circle bg-vertical-gradient-2"></span>
									</label>
								</div>
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-color-gradient-3" v-model="sidebarColor" class="form-check-input" type="radio" name="data-sidebar" value="gradient-3" />
									<label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient-3">
										<span class="avatar-title rounded-circle bg-vertical-gradient-3"></span>
									</label>
								</div>
								<div class="form-check sidebar-setting card-radio">
									<input id="sidebar-color-gradient-4" v-model="sidebarColor" class="form-check-input" type="radio" name="data-sidebar" value="gradient-4" />
									<label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient-4">
										<span class="avatar-title rounded-circle bg-vertical-gradient-4"></span>
									</label>
								</div>
							</div>
						</BCollapse>
					</div>

					<div v-if="layoutType == 'vertical' || layoutType === 'twocolumn' || (layoutType === 'semibox' && visibility === 'show')" id="sidebar-img">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Sidebar Images</h6>
						<p class="text-muted">Choose a image of Sidebar.</p>

						<div class="d-flex gap-2 flex-wrap img-switch">
							<div class="form-check sidebar-setting card-radio">
								<input id="sidebarimg-none" v-model="sidebarImage" class="form-check-input" type="radio" name="data-sidebar-img" value="none" />
								<label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-none">
									<span class="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
										<i class="ri-close-fill fs-20"></i>
									</span>
								</label>
							</div>

							<div class="form-check sidebar-setting card-radio">
								<input id="sidebarimg-01" v-model="sidebarImage" class="form-check-input" type="radio" name="data-sidebar-img" value="img-1" />
								<label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-01">
									<img src="@/assets/images/sidebar/img-1.jpg" alt="" class="avatar-md w-auto object-fit-cover" />
								</label>
							</div>

							<div class="form-check sidebar-setting card-radio">
								<input id="sidebarimg-02" v-model="sidebarImage" class="form-check-input" type="radio" name="data-sidebar-img" value="img-2" />
								<label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-02">
									<img src="@/assets/images/sidebar/img-2.jpg" alt="" class="avatar-md w-auto object-fit-cover" />
								</label>
							</div>
							<div class="form-check sidebar-setting card-radio">
								<input id="sidebarimg-03" v-model="sidebarImage" class="form-check-input" type="radio" name="data-sidebar-img" value="img-3" />
								<label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-03">
									<img src="@/assets/images/sidebar/img-3.jpg" alt="" class="avatar-md w-auto object-fit-cover" />
								</label>
							</div>
							<div class="form-check sidebar-setting card-radio">
								<input id="sidebarimg-04" v-model="sidebarImage" class="form-check-input" type="radio" name="data-sidebar-img" value="img-4" />
								<label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-04">
									<img src="@/assets/images/sidebar/img-4.jpg" alt="" class="avatar-md w-auto object-fit-cover" />
								</label>
							</div>
						</div>
					</div>

					<div id="preloader-menu">
						<h6 class="mt-4 mb-0 fw-bold text-uppercase">Preloader</h6>
						<p class="text-muted">Choose a preloader.</p>

						<BRow>
							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="preloader-view-custom" v-model="preloader" class="form-check-input" type="radio" name="data-preloader" value="enable" />
									<label class="form-check-label p-0 avatar-md w-100" for="preloader-view-custom">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
										<div id="status" class="d-flex align-items-center justify-content-center">
											<div class="spinner-border text-primary avatar-xxs m-auto" role="status">
												<span class="visually-hidden">Loading...</span>
											</div>
										</div>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Enable</h5>
							</BCol>
							<BCol cols="4">
								<div class="form-check sidebar-setting card-radio">
									<input id="preloader-view-none" v-model="preloader" class="form-check-input" type="radio" name="data-preloader" value="disable" />
									<label class="form-check-label p-0 avatar-md w-100" for="preloader-view-none">
										<span class="d-flex gap-1 h-100">
											<span class="flex-shrink-0">
												<span class="bg-light d-flex h-100 flex-column gap-1 p-1">
													<span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
													<span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
												</span>
											</span>
											<span class="flex-grow-1">
												<span class="d-flex h-100 flex-column">
													<span class="bg-light d-block p-1"></span>
													<span class="bg-light d-block p-1 mt-auto"></span>
												</span>
											</span>
										</span>
									</label>
								</div>
								<h5 class="fs-13 text-center mt-2">Disable</h5>
							</BCol>
						</BRow>
					</div>
				</div>
			</simpleBar>
			<template #footer>
				<BRow>
					<BCol cols="6">
						<BButton id="reset-layout" type="button" variant="light" class="w-100" @click="resetLayout">Reset</BButton>
					</BCol>
					<BCol cols="6">
						<BButton href="https://1.envato.market/streamline-admin" target="_blank" variant="primary" class="w-100">Buy Now</BButton>
					</BCol>
				</BRow>
			</template>
		</BOffcanvas>
	</div>
</template>

<style lang="scss">
.b-overlay-wrap {
	.b-overlay {
		z-index: 1005 !important;
	}
}
</style>
