<template>
	<footer v-if="$route.path !== '/apps/deals'" class="footer">
		<BContainer fluid>
			<BRow>
				<BCol col sm="6">{{ new Date().getFullYear() }} © Gustin Quon.</BCol>
				<BCol col sm="6">
					<div class="text-sm-end d-none d-sm-block">Developed by Corey Jansen</div>
				</BCol>
			</BRow>
		</BContainer>
	</footer>
</template>
