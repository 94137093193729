<template>
	<router-view :key="$route.fullPath"></router-view>
</template>

<script>
import {getAuth, onAuthStateChanged} from "firebase/auth";

export default {
	name: "App",
	components: {},
	mounted() {
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is logged in, dispatch actions to fetch settings and users
				this.$store.dispatch("settings/fetchSettings");
				this.$store.dispatch("users/fetchAllUsers");
			} else {
				// User is not logged in, you can handle this case as needed
				console.log("User is not logged in.");
			}
		});

		const debounce = (callback, delay) => {
			let tid;
			return function (...args) {
				const ctx = this;
				tid && clearTimeout(tid);
				tid = setTimeout(() => {
					callback.apply(ctx, args);
				}, delay);
			};
		};

		const _ = window.ResizeObserver;
		window.ResizeObserver = class ResizeObserver extends _ {
			constructor(callback) {
				callback = debounce(callback, 70);
				super(callback);
			}
		};
	},
};
</script>
