// Adjust the function to take the users array as a parameter
export function findUserInfo(user) {
	const users = this.$store.getters["users/users"];

	let searchKey = "uid";
	let searchValue = user;
	if (!user) {
		return {
			displayName: "",
			photoUrl: "https://ui-avatars.com/api/?name=Unknown&color=7F9CF5&background=EBF4FF",
			uid: "",
			email: "",
		};
	}
	if (Object.prototype.hasOwnProperty.call(user, "uid")) {
		searchValue = user.uid;
	} else if (Object.prototype.hasOwnProperty.call(user, "email")) {
		searchKey = "email";
		searchValue = user.email;
	}
	// console.log(users)
	var foundUser = users.find((row) => row[searchKey] === searchValue);
	if (!foundUser) {
		foundUser = {
			displayName: "",
			photoUrl: "https://ui-avatars.com/api/?name=Unknown&color=7F9CF5&background=EBF4FF",
			uid: searchValue,
			email: "noreply@example.com",
		};
	}
	return foundUser;
}
