import SettingsDataService from "@/common/data/firestore/settings/index.js";



export const state = {
    settings: {},
    loaded: false,
    loadPromise: null, // Add a promise state to track the loading status
  };
  
  export const getters = {
    areSettingsLoaded: state => state.loaded,
    settings: state => state.settings,
  };
  
  export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
		dispatch("fetchSettings");
	},
    fetchSettings({ commit, state }) {
      // Check if settings are already loaded, or if a fetch operation is in progress
      if (!state.loaded && !state.loadPromise) {
        console.log("Fetching settings");
  
        // Create a new promise and store it in the state
        const loadPromise = SettingsDataService.getAll()
          .then(settings => {
            commit('SET_SETTINGS', settings);
            commit('SET_LOADED', true);
          })
          .catch(error => {
            console.error("Error fetching settings:", error);
            // Handle error, for example by setting an 'error' state or showing a notification
          })
          .finally(() => {
            commit('SET_LOAD_PROMISE', null); // Clear the promise after loading is complete
          });
  
        // Commit the promise to the state
        commit('SET_LOAD_PROMISE', loadPromise);
      }
  
      // If a fetch operation is in progress, return the existing promise
      return state.loadPromise;
    }
  };
  
  export const mutations = {
    SET_SETTINGS(state, settings) {
      console.log(`Settings: ${settings}`);
      state.settings = settings;
    },
    SET_LOADED(state, loaded) {
      state.loaded = loaded;
    },
    SET_LOAD_PROMISE(state, loadPromise) {
      state.loadPromise = loadPromise;
    }
  };
