import { addDoc } from "firebase/firestore";

import { mailCollection } from "./firebase";

const db = mailCollection;

class EmailsDataService {
    async create(deal) {
        return await addDoc(db, deal);
    }
}

export default new EmailsDataService();
