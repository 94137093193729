import store from "@/state/store";

import DocView from "@/views/doc/DocView.vue"; // Import the new component

export default [
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/account/login.vue"),
		meta: {
			title: "Login",
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					const redirectFrom = routeTo.query.redirectFrom || "/";
					// Redirect to the URL specified by the redirectFrom parameter or to the default route
					next({ path: redirectFrom });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/register",
		name: "register",
		component: () => import("@/views/account/register.vue"),
		meta: {
			title: "Register",
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "default" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/forgot-password",
		name: "Forgot password",
		component: () => import("@/views/account/forgot-password.vue"),
		meta: {
			title: "Forgot Password",
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "default" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/",
		name: "default",
		redirect: "/apps/deals",
		//component: () => import("@/views/dashboard/ecommerce/index.vue"),
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			title: "Logout",
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				localStorage.clear();
				sessionStorage.clear();
				store.dispatch("auth/logOut");
				next();
			},
		},
		component: () => import("@/views/auth/logout/basic"),
	},

	{
		path: "/auth/403",
		name: "403",
		meta: {
			title: "Error 403",
			authRequired: true,
		},
		component: () => import("@/views/auth/errors/403"),
	},
	{
		path: "/auth/success",
		name: "success",
		meta: {
			title: "Success",
			authRequired: true,
		},
		component: () => import("@/views/auth/success-msg/basic"),
	},
	{
		path: "/auth/500",
		name: "500",
		meta: {
			title: "Error 500",
			authRequired: true,
		},
		component: () => import("@/views/auth/errors/500"),
	},
	{
		path: "/account/tasks",
		name: "account-tasks",
		meta: { title: "Tasks", authRequired: true },
		component: () => import("@/views/account/tasks"),
	},

	{
		path: "/settings/training/companies",
		name: "settings-training-companies",
		meta: { title: "Training Companies", authRequired: true, requiredClaims: { p_viewTrainingCompanies: true } },
		component: () => import("@/views/settings/training/companies/index"),
	},
	{
		path: "/settings/training/courses",
		name: "settings-training-courses",
		meta: { title: "Training Courses", authRequired: true, requiredClaims: { p_viewTrainingCompanies: true } },
		component: () => import("@/views/settings/training/companies/courses"),
	},
	{
		path: "/settings/meetings/event-types",
		name: "settings-meetings-event-types",
		meta: { title: "Event Types", authRequired: true, requiredClaims: { p_viewEventTypes: true } },
		component: () => import("@/views/settings/meetings"),
	},
	{
		path: "/settings/users",
		name: "settings-users",
		meta: { title: "Users", authRequired: true, requiredClaims: { p_manageUsers: true } },
		component: () => import("@/views/settings/users"),
	},
	{
		path: "/settings/referral-sources",
		name: "settings-referral-sources",
		meta: { title: "Referral Sources", authRequired: true, requiredClaims: { p_viewReferralSources: true } },
		component: () => import("@/views/settings/referralSources"),
	},
	{
		path: "/settings/create-emails",
		name: "settings-create-emails",
		meta: { title: "Create Domain and Emails", authRequired: true, requiredClaims: { p_editEmails: true } },
		component: () => import("@/components/domains/create.vue"),
	},
	{
		path: "/settings/deal-statuses",
		name: "settings-deal-statuses",
		meta: { title: "Deal Statuses", authRequired: true, requiredClaims: { p_editEmails: true } },
		component: () => import("@/views/settings/dealStatuses"),
	},
	{
		path: "/apps/deals",
		name: "crm-deals",
		meta: { title: "CRM Deals", authRequired: true, requiredClaims: { p_viewDeals: true } },
		component: () => import("@/views/crm/deals/index"),
	},
	{
		path: "/apps/event-types/sales",
		name: "event-types-sales",
		meta: { title: "Sales", authRequired: true },
		component: () => import("@/views/crm/event-types/sales"),
	},
	{
		path: "/apps/deals/create",
		name: "crm-deals-create",
		meta: { title: "Create Deal", authRequired: true, requiredClaims: { p_createDeals: true } },
		component: () => import("@/views/crm/deals/create"),
	},
	{
		path: "/apps/deals/:uniqueId",
		redirect: (to) => ({
			path: `/apps/deals/${to.params.uniqueId}/edit`,
			props: true,
		}),
	},
	{
		path: "/apps/deals/:uniqueId/edit",
		name: "crm-deals-edit",
		component: () => import("@/views/crm/deals/create"),
		props: true,
		meta: { title: "Edit Deal", authRequired: true, requiredClaims: { p_editDeals: true } },
	},
	{
		path: "/apps/deals/:uniqueId/employees",
		name: "crm-deals-edit-employees",
		component: () => import("@/views/crm/deals/training/employees/list"),
		props: true,
		meta: { title: "Edit Employees", authRequired: false },
	},

	{
		path: "/dashboard/voicemails",
		name: "dashboard-voicemails",
		meta: { title: "Voicemails", authRequired: true },
		component: () => import("@/views/dashboard/voicemails/list"),
	},
	{
		path: "/dashboard/training-emails",
		name: "training-emails",
		meta: { title: "Training Emails", authRequired: true, requiredClaims: { p_viewTrainingGrantEmails: true } },
		component: () => import("@/views/dashboard/emails/TrainingEmails.vue")
	},
	{
		path: "/dashboard/meetings",
		name: "dashboard-meetings",
		meta: { title: "Meetings", authRequired: true },
		component: () => import("@/views/dashboard/reports/ScheduledMeetings.vue"),
	},
	{
		path: "/dashboard/feature-usage",
		name: "dashboard-feature-usage",
		meta: { title: "Feature Usage", authRequired: true },
		component: () => import("@/views/dashboard/reports/FeatureUsage.vue"),
	},
	{
		path: "/dashboard/admin/run-functions",
		name: "dashboard-admin-run-functions",
		meta: { title: "Run Functions", authRequired: true },
		component: () => import("@/views/dashboard/admin/RunFunctions.vue"),
	},
	{
		path: "/dashboard/virtual-assistants",
		name: "dashboard-virtual-assistants",
		meta: { title: "Virtual Assistant Availability", authRequired: true },
		component: () => import("@/views/dashboard/reports/VirtualAssistants.vue"),
	},
	{
		path: "/dashboard/sales-stats",
		name: "dashboard-sales-stats",
		meta: { title: "Sales Stats", authRequired: true },
		component: () => import("@/views/dashboard/reports/SalesStats.vue")
	},
	{
		path: "/doc/:docId",
		name: "doc-view",
		component: DocView,
		meta: { title: "Document View", authRequired: true },
		props: true,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "404",
		meta: {
			title: "Error 404",
			authRequired: false,
		},
		component: () => import("@/views/auth/errors/404-basic"),
	},
];
//requiredClaims: {p_viewVoicemails: true}
