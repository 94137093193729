import { arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, increment, onSnapshot, orderBy, query, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";

import { usersCollection } from "./firebase";

const db = usersCollection;

class UsersDataService {
	async getAll() {
		const orderedQuery = query(db, orderBy("email"));
		const querySnapshot = await getDocs(orderedQuery);
		const users = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
		return users;
	}
	async getDocument(id) {
		const dealDoc = doc(db, id);
		const docSnap = await getDoc(dealDoc);
		if (docSnap.exists()) {
			return { ...docSnap.data(), id: docSnap.id };
		} else {
			console.log("No such document!");
		}
	}
	async create(id, deal) {
		const dealDoc = doc(db, id);
		return await setDoc(dealDoc, deal);
	}

	async update(id, value) {
		const dealDoc = doc(db, id);
		return await updateDoc(dealDoc, value);
	}

	async delete(id) {
		const dealDoc = doc(db, id);
		return await deleteDoc(dealDoc);
	}
	async copy(copyFrom, newId) {
		const dealDoc = doc(db, newId);
		const dealDocCopyFrom = doc(db, copyFrom);
		const docSnap = await getDoc(dealDocCopyFrom);
		if (docSnap.exists()) {
			return await setDoc(dealDoc, docSnap.data());
		} else {
			console.log("No such document!");
		}
	}
	async trackInteraction(userId, featureId) {
		const interactionRef = doc(db, `${userId}/interactions/${featureId}`);
		const interactionSnap = await getDoc(interactionRef);

		if (interactionSnap.exists()) {
			// Update existing interaction
			await updateDoc(interactionRef, {
				count: increment(1),
				lastUsed: serverTimestamp(),
				dates: arrayUnion(new Date().toISOString())
			});
		} else {
			// Create a new interaction
			await setDoc(interactionRef, {
				count: 1,
				lastUsed: serverTimestamp(),
				dates: arrayUnion(new Date().toISOString())
			});
		}
	}

	async getInteractions(userId) {
		const interactionsRef = collection(db, `${userId}/interactions`);
		const querySnapshot = await getDocs(interactionsRef);
		const interactions = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
		return interactions;
	}

	listenToInteractions(userId, callback) {
		const interactionsRef = collection(db, `${userId}/interactions`);
		onSnapshot(interactionsRef, (querySnapshot) => {
			const interactions = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			callback(userId, interactions);
		});
	}
}

export default new UsersDataService();
