import UsersDataService from "@/common/data/firestore/users";

export const state = {
	users: [],
	loading: false,
	loadPromise: null,
};

export const getters = {
	users: (state) => state.users,
};

export const mutations = {
	SET_USERS(state, users) {
		state.users = users;
	},
	SET_LOADING(state, loading) {
		state.loading = loading;
	},
	SET_LOAD_PROMISE(state, promise) {
		state.loadPromise = promise;
	},
};

export const actions = {
	// eslint-disable-next-line no-unused-vars
	init({ state, dispatch }) {
		dispatch("fetchAllUsers");
	},
	fetchAllUsers({ state, commit }) {
		// If users are already loaded or a fetch operation is in progress, return the existing promise
		if (state.users.length > 0 || state.loadPromise) {
			return state.loadPromise;
		}

		// Create a new promise if users are not loaded and no fetch operation is in progress
		const loadPromise = UsersDataService.getAll()
			.then((response) => {
				const users = response.map((user) => ({
					...user,
					value: user.uid,
				}));
				commit('SET_USERS', users);
			})
			.catch((error) => {
				console.error('Error fetching users:', error);
				// Handle error appropriately
			})
			.finally(() => {
				// Remove the promise after the operation completes
				commit('SET_LOAD_PROMISE', null);
			});

		commit('SET_LOAD_PROMISE', loadPromise);

		return loadPromise;
	},
};