<script>
import Footer from "@/components/footer";
import NavBar from "@/components/nav-bar";
import RightBar from "@/components/right-bar";

export default {
  components: {
    NavBar,
    RightBar,
    Footer
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  mounted(){
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
                siblingCollapse.parentElement.firstChild.setAttribute("aria-expanded", "false");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2) {
                item.firstElementChild.setAttribute("aria-expanded", "false");
                item.firstElementChild.classList.remove("active");
              }
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                item1.parentElement.firstChild.setAttribute("aria-expanded", "false");
                item1.parentElement.firstChild.classList.remove("active");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");
                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });
        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.classList.remove("show");
            childCollapseInstance.parentElement.firstChild.setAttribute("aria-expanded", "false");
          });
        });
      });
    }
  },
  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="17" />
            </span>
          </router-link>
          <button id="vertical-hover" type="button" class="
              btn btn-sm
              p-0
              fs-20
              header-item
              float-end
              btn-vertical-sm-hover
            ">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>
        <div id="scrollbar">
          <BContainer fluid>
            <ul id="navbar-nav" class="navbar-nav h-100">
              <li class="menu-title">
                <span data-key="t-menu"> {{ $t("t-menu") }}</span>
              </li>
              <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarDashboards">
                  <i class="bx bxs-dashboard"></i>
                  <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
                </a>
                <div id="sidebarDashboards" class="collapse menu-dropdown">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/" class="nav-link" data-key="t-ecommerce">
                        {{ $t("t-ecommerce") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- end Dashboard Menu -->

              <li class="menu-title">
                <i class="ri-more-fill"></i>
                <span data-key="t-pages">{{ $t("t-pages") }}</span>
              </li>

              <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarAuth" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarAuth">
                  <i class="ri-account-circle-line"></i>
                  <span data-key="t-authentication">{{
                    $t("t-authentication")
                  }}</span>
                </a>
                <div id="sidebarAuth" class="collapse menu-dropdown">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarSignIn" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin">{{ $t("t-signin") }}
                      </a>
                      <div id="sidebarSignIn" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/signin-basic" class="nav-link" data-key="t-basic">{{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/signin-cover" class="nav-link" data-key="t-cover">{{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarSignUp" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarSignUp" data-key="t-signup">{{ $t("t-signup") }}
                      </a>
                      <div id="sidebarSignUp" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/signup-basic" class="nav-link" data-key="t-basic">{{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/signup-cover" class="nav-link" data-key="t-cover">{{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarResetPass" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarResetPass" data-key="t-password-reset">
                        {{ $t("t-password-reset") }}
                      </a>
                      <div id="sidebarResetPass" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/reset-pwd-basic" class="nav-link" data-key="t-basic">
                              {{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/reset-pwd-cover" class="nav-link" data-key="t-cover">
                              {{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarcreatepass" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarcreatepass" data-key="t-password-reset">
                        {{ $t("t-password-create") }}
                      </a>
                      <div id="sidebarcreatepass" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/create-pwd-basic" class="nav-link" data-key="t-basic">
                              {{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/create-pwd-cover" class="nav-link" data-key="t-cover">
                              {{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarLockScreen" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarLockScreen" data-key="t-lock-screen">
                        {{ $t("t-lock-screen") }}
                      </a>
                      <div id="sidebarLockScreen" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/lockscreen-basic" class="nav-link" data-key="t-basic">
                              {{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/lockscreen-cover" class="nav-link" data-key="t-cover">
                              {{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarLogout" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarLogout" data-key="t-logout">
                        {{ $t("t-logout") }}
                      </a>
                      <div id="sidebarLogout" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/logout-basic" class="nav-link" data-key="t-basic">
                              {{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/logout-cover" class="nav-link" data-key="t-cover">
                              {{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarSuccessMsg" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarSuccessMsg" data-key="t-success-message">
                        {{ $t("t-success-message") }}
                      </a>
                      <div id="sidebarSuccessMsg" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/success-msg-basic" class="nav-link" data-key="t-basic">
                              {{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/success-msg-cover" class="nav-link" data-key="t-cover">
                              {{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarTwoStep" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarTwoStep" data-key="t-two-step-verification">
                        {{ $t("t-two-step-verification") }}
                      </a>
                      <div id="sidebarTwoStep" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/twostep-basic" class="nav-link" data-key="t-basic">
                              {{ $t("t-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/twostep-cover" class="nav-link" data-key="t-cover">
                              {{ $t("t-cover") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#sidebarErrors" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarErrors" data-key="t-errors">
                        {{ $t("t-errors") }}
                      </a>
                      <div id="sidebarErrors" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <router-link to="/auth/404-basic" class="nav-link" data-key="t-404-basic">
                              {{ $t("t-404-basic") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/404-cover" class="nav-link" data-key="t-404-cover">
                              {{ $t("t-404-cover") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/404" class="nav-link" data-key="t-404-alt">
                              {{ $t("t-404-alt") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/500" class="nav-link" data-key="t-500">
                              {{ $t("t-500") }}
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link to="/auth/ofline" class="nav-link" data-key="t-ofline-page">
                              {{ $t("t-offline-page") }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarPages" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarPages">
                  <i class="bx bx-file"></i>
                  <span data-key="t-pages">{{ $t("t-pages") }}</span>
                </a>
                <div id="sidebarPages" class="collapse menu-dropdown">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/pages/starter" class="nav-link" data-key="t-starter">{{ $t("t-starter") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/pages/maintenance" class="nav-link" data-key="t-maintenance">
                        {{ $t("t-maintenance") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/pages/coming-soon" class="nav-link" data-key="t-coming-soon">
                        {{ $t("t-coming-soon") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="menu-title">
                <i class="ri-more-fill"></i>
                <span data-key="t-components">{{ $t("t-components") }}</span>
              </li>


              <li class="nav-item">
                <a class="nav-link" href="#sidebarMore" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarMore">
                  <i class="ri-briefcase-2-line"></i> More
                </a>
                <div id="sidebarMore" class="collapse menu-dropdown show">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <a class="nav-link menu-link" href="#sidebarMultilevel" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarMultilevel">
                        <i class="ri-share-line"></i>
                        <span data-key="t-multi-level">{{
                          $t("t-multi-level")
                        }}</span>
                      </a>
                      <div id="sidebarMultilevel" class="collapse menu-dropdown">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <a class="nav-link" data-key="t-level-1.1">
                              {{ $t("t-level-1.1") }}
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#sidebarAccount" data-bs-toggle="collapse" role="button"
                              aria-expanded="false" aria-controls="sidebarAccount" data-key="t-level-1.2">
                              {{ $t("t-level-1.2") }}
                            </a>
                            <div id="sidebarAccount" class="collapse menu-dropdown">
                              <ul class="nav nav-sm flex-column">
                                <li class="nav-item">
                                  <a class="nav-link" data-key="t-level-2.1">
                                    {{ $t("t-level-2.1") }}
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" href="#sidebarCrm" data-bs-toggle="collapse" role="button"
                                    aria-expanded="false" aria-controls="sidebarCrm" data-key="t-level-2.2">
                                    {{ $t("t-level-2.2") }}
                                  </a>
                                  <div id="sidebarCrm" class="collapse menu-dropdown">
                                    <ul class="nav nav-sm flex-column">
                                      <li class="nav-item">
                                        <a class="nav-link" data-key="t-level-3.1">
                                          {{ $t("t-level-3.1") }}
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a class="nav-link" data-key="t-level-3.2">
                                          {{ $t("t-level-3.2") }}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </BContainer>
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <BContainer fluid>
            <slot />
          </BContainer>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>